import UploadIcon from '@mui/icons-material/Upload'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import CheckIcon from '@mui/icons-material/Check'
import React, { useCallback } from 'react'
import { Checkbox, TableRow, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Warning } from '@mui/icons-material'
import { IconUser } from '@tabler/icons-react'
import EnhancedTableCell from '../EnhancedTableCell'
import classes from './style.module.scss'
import { useSelector } from '../../../../store/store'
import { convertValuesToLabels, fixImgSrc, hasPermission } from '../../../../utils/tools'
import NoProvidedCustomerEmail from '../../../../ui-component/NoProvidedCustomerEmail'
import { isEndCustomer, USER_ROLES } from '../../../../constants'
import AdvancedMenu from '../../../../components/AdvancedMenu/AdvancedMenu'
import { customerTypes } from '../../../customers/create/CreateCustomer'

const OrdersRow = ({ labelId = '', row, isItemSelected = false, checkbox = false }) => {
  const {
    id,
    logoUrl,
    status,
    jobType,
    customerName,
    customerType,
    connectedReseller,
    jobName,
    place,
    createdAt,
    assignee,
    channel,
    budget,
    price,
    runtime,
    customerEmails,
    previews,
  } = row

  const navigate = useNavigate()
  const customerHasEmail = customerEmails ? JSON.parse(customerEmails)?.length : null

  const handleClick = useCallback(() => {
    navigate(`/orders/${id}`)
  }, [id, navigate])

  const { permissions, userInfo } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.orderDetailsFields)
  const { role } = userInfo

  const TableCellOrder = (
    props: JSX.IntrinsicAttributes & {
      children: React.ReactNode
      action?: boolean | undefined
      checkbox?: boolean | undefined
      handleClick?: (() => void) | undefined
    }
  ) => {
    return (
      <EnhancedTableCell id={id} handleClick={handleClick} {...props}>
        {props.children}
      </EnhancedTableCell>
    )
  }

  const preview = previews?.length ? setPreviewStatus(status) : ''

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
    >
      {checkbox && (
        <TableCellOrder checkbox action>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCellOrder>
      )}
      {permission('customer') && !isEndCustomer(role) && (
        <>
          <TableCellOrder>
            <div className={classes.nameAndLogo}>
              <div className={classes.logo}>
                {logoUrl ? (
                  <img src={fixImgSrc(logoUrl)} alt="customer logo" width="33px" />
                ) : (
                  <IconUser width="35px" />
                )}
              </div>
              <div className={classes.textContent}>
                <Typography>
                  {customerName}
                  {!customerHasEmail && <NoProvidedCustomerEmail />}
                </Typography>
                <Typography fontSize="13px" fontStyle="italic" color="secondary">
                  {convertValuesToLabels(customerTypes, customerType)}
                </Typography>
              </div>
            </div>
          </TableCellOrder>
          {role !== USER_ROLES.EXTERNAL_RESELLER.value && (
            <TableCellOrder>{connectedReseller}</TableCellOrder>
          )}
        </>
      )}
      {permission('jobName') && (
        <TableCellOrder>
          <div className={classes.jobType}>
            <Typography>{jobName}</Typography>
            {permission('jobType') && (
              <Typography color="secondary" fontSize="13px" fontStyle="italic">
                {jobType}
              </Typography>
            )}
          </div>
        </TableCellOrder>
      )}
      {permission('status') && (
        <TableCellOrder>
          <div
            className={classes.statusOrders}
            style={{ color: status === 'unpublished' ? '#EE7202' : 'inherit' }}
          >
            {status}
            <span className={classes.space}></span>
            {status === 'unpublished' && <Warning fontSize={'small'} />}
          </div>
        </TableCellOrder>
      )}
      {permission('place') && <TableCellOrder>{place}</TableCellOrder>}
      {permission('createdAt') && (
        <TableCellOrder>{dayjs(createdAt).format('DD/MM/YYYY')}</TableCellOrder>
      )}
      {permission('assignee') && <TableCellOrder>{assignee}</TableCellOrder>}
      <TableCellOrder>{preview}</TableCellOrder>
      {permission('channel') && <TableCellOrder>{channel}</TableCellOrder>}
      {permission('budget') && role !== USER_ROLES.EXTERNAL_RESELLER_CUSTOMER.value && (
        <TableCellOrder>{budget} €</TableCellOrder>
      )}
      {permission('price') && role !== USER_ROLES.EXTERNAL_RESELLER_CUSTOMER.value && (
        <TableCellOrder>{price} €</TableCellOrder>
      )}
      {permission('runtime') && <TableCellOrder>{runtime}</TableCellOrder>}
      <TableCellOrder action>
        <AdvancedMenu id={id} type={'order'} />
      </TableCellOrder>
    </TableRow>
  )
}

export default OrdersRow

const setPreviewStatus = (status) => {
  switch (status) {
    case 'Waiting for approval':
      return (
        <Tooltip title="Waiting for approval">
          <HourglassTopIcon />
        </Tooltip>
      )
    case 'Approved':
      return (
        <Tooltip title="Approved">
          <CheckIcon color="success" />
        </Tooltip>
      )

    default:
      return (
        <>
          <Tooltip title="Preview uploaded">
            <UploadIcon />
          </Tooltip>
        </>
      )
  }
}
