// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountsAndPermissions .buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 95%;
  gap: 2%;
}`, "",{"version":3,"sources":["webpack://./src/views/accountsAndPermissions/style.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;EACA,OAAA;AAAJ","sourcesContent":[".AccountsAndPermissions {\n  .buttons {\n    display: flex;\n    width: 100%;\n    justify-content: flex-end;\n    height: 95%;\n    gap: 2%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
