import useSWR, { SWRResponse } from 'swr'
import { MoreHorizRounded } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Modal } from '@mui/material'
import React, { useCallback } from 'react'
import './style.scss'
import { History } from './options/History'
import axiosInstance from '../../utils/axiosInstance'

interface Props {
  id: string
  type?: string
}

const AdvancedMenu: React.FC<Props> = ({ id, type = '' }) => {
  const { data }: SWRResponse = useSWR(`/orderCampaignId/${id}`, axiosInstance)
  const campaignId = data?.campaignId

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    handleCloseModal()
  }

  const handleClickOption = () => {
    setAnchorEl(null)
    handleOpenModal()
  }

  const handleDownloadCB = useCallback(() => {
    const url = `/campaign/${id}/download`
    axiosInstance
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `campaign_${id}.csv`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => {
        handleCloseModal()
      })
  }, [id])

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizRounded />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {type === 'lead' ? (
          <div>
            <MenuItem onClick={() => {}}>Edit</MenuItem>
            <MenuItem onClick={handleDownloadCB}>Download</MenuItem>
          </div>
        ) : (
          <>
            <MenuItem onClick={handleClickOption}>History</MenuItem>
            {campaignId && (
              <MenuItem onClick={() => window.open(`/campaigns/${campaignId}/`, '_blank')}>
                Go to Campaign
              </MenuItem>
            )}
          </>
        )}
      </Menu>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <History id={id} handleClose={() => handleClose()} />
        </div>
      </Modal>
    </div>
  )
}

export default AdvancedMenu
