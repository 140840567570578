// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddNewItem .textField {
  display: flex;
  align-items: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/ui-component/addNewItem/style.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAAJ","sourcesContent":[".AddNewItem {\n  .textField {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
