import { configureStore } from '@reduxjs/toolkit'
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux'

import general from './slices/general'
import user from './slices/user'
import menu from './slices/menu'
import snackbar from './slices/snackbar'
import lead from './slices/lead'
import table from './slices/table'
import filters from './slices/filters/filters'
import dialog from './slices/dialog'
import app from './slices/app'

const store = configureStore({
  reducer: {
    general,
    user,
    menu,
    snackbar,
    lead,
    table,
    filters,
    dialog,
    app,
  },
})

const { dispatch } = store

const useDispatch = () => useAppDispatch<AppDispatch>()
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { store, dispatch, useSelector, useDispatch }
