import { useParams, useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import useSWR, { SWRResponse } from 'swr'
import DownloadIcon from '@mui/icons-material/Download'
import { IconCirclePlus } from '@tabler/icons-react'
// import AddIcon from '@mui/icons-material/Add'
import { Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import axiosInstance from '../../../utils/axiosInstance'
import BoxWrapper from '../../../components/BoxWrapper'
import TableRender from '../../../components/TableRender'
import { leadStatuses } from '../../../constants'
import classes from './style.module.scss'
import { useDispatch, useSelector } from '../../../store/store'
import Loading from '../../../ui-component/loading/Loading'
import { dialogToggle } from '../../../store/slices/dialog'
import { setTrigger } from '../../../store/slices/lead'
import CreateLead from './leadDetail/CreateLead'

interface Lead {
  id: string
  status?: string
  createdAt: Dayjs | string
  firstName: string
  lastName: string
  phone: string
  email: string
  portal: string
  channel: string
  question: {
    name: string
    value: string
  }
}

interface Info {
  question: {
    name: string
    value: string
  }
  firstName: string
  lastName: string
  email: string
  phone: string
}

function createData(
  id: string,
  createdAt: Dayjs | string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  portal: string,
  channel: string,
  question: {
    name: string
    value: string
  },
  status?: string
): Lead {
  return {
    id,
    createdAt,
    firstName,
    lastName,
    phone,
    email,
    portal,
    channel,
    question,
    status,
  }
}

export const LeadsList = () => {
  const { id } = useParams()

  const [rows, setRows] = useState<unknown[] | never[]>()
  const [page, setPage] = useState(0)
  const [openCreateLead, setOpenCreateLead] = useState(false)
  const defaultRowsPerPage = Number(localStorage.getItem('campaignTableRowsPerPage')) || 5
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  // const { data: totalOrders } = useSWR(`/campaigns/${id}/total-count`, axiosInstance)
  // const totalCount = totalOrders?.data.data

  const take = page * rowsPerPage + rowsPerPage + 1
  const requestPath = `/campaign?take=${take}`
  const axiosCall = () => {
    return axiosInstance.get(requestPath, {
      params: { id },
    })
  }
  const [searchParams] = useSearchParams()
  const leadsId = searchParams.get('lead') || null
  const { trigger } = useSelector((state) => state.lead)
  const dispatch = useDispatch()
  const theme = useTheme()

  const { data, isLoading, mutate }: SWRResponse = useSWR(requestPath, axiosCall, {
    revalidateIfStale: true,
    revalidateOnMount: true,
  })

  useEffect(() => {
    if (trigger) {
      mutate()
      dispatch(setTrigger(false))
    }
  }, [dispatch, mutate, trigger])

  const statusCounter = (data, status) => ({
    [status]: data?.filter((item) => item.status === status).length,
  })

  React.useEffect(() => {
    if (leadsId) {
      dispatch(dialogToggle(true))
    }
  }, [dispatch, leadsId])

  React.useEffect(() => {
    if (data?.data.campaignTitle) {
      const rowsData = data?.data?.leads.map((lead) => {
        const { id, createdTime: createdAt, fieldData, platform, status } = lead
        const reformat: Info = {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          question: { name: '', value: '' },
        }

        JSON.parse(fieldData).forEach((item) => {
          switch (item.name) {
            case 'full_name':
              reformat.firstName = item?.values[0].split(' ')[0]
              reformat.lastName = item?.values[0].split(' ')[1]
              break
            case 'email':
              reformat.email = item?.values[0]
              break
            case 'phone_number':
              reformat.phone = item?.values[0]
              break
            default:
              reformat.question = { name: item.name, value: item.values }
              break
          }
        })

        const { firstName, lastName, phone, email, question } = reformat
        const portal = platform
        const channel = 'N/A'
        return createData(
          id,
          dayjs(createdAt),
          firstName,
          lastName,
          phone,
          email,
          portal,
          channel,
          question,
          status
        )
      })

      setRows(rowsData)
    }
  }, [data, dispatch])

  const handleDownloadCB = useCallback(() => {
    const url = `/campaign/${id}/download`
    axiosInstance
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `campaign_${id}.csv`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [id])

  const StatsItem = ({ status: { label, value }, rows }) => {
    const getStatuses = statusCounter(rows, value)

    return (
      <Grid
        item
        xs="auto"
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          borderRadius: '8px',
          flex: '1 1 auto',
          border: '1px solid  #EEE',
        }}
      >
        <p className={`${classes.status} ${classes[value]}`}>{label}</p>
        <p
          className={`${classes.status} ${classes[value]}`}
          style={{ width: '20px', height: '20px' }}
        >
          {getStatuses[value]}
        </p>
      </Grid>
    )
  }

  return (
    <BoxWrapper height="100%">
      <div className={classes.tableHeader}>
        <div className={classes.header}>
          {data?.data?.campaignTitle && (
            <div className={classes.header}>
              <div className={classes.titleContainer}>
                <Typography variant="h2">{data?.data?.campaignTitle}</Typography>
                <span className={classes.counter}>({data?.data?.leads?.length || 0})</span>
              </div>
              <Tooltip onClick={handleDownloadCB} title="Download CSV">
                <IconButton>
                  <DownloadIcon style={{ color: '#757575', height: '1em' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add new" onClick={() => setOpenCreateLead(true)}>
                <IconButton>
                  <IconCirclePlus
                    stroke="none"
                    size="35px"
                    fill={theme.palette.primary.main}
                    color={theme.palette.primary.light}
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <CreateLead open={openCreateLead} setOpen={setOpenCreateLead} />
      <Grid container justifyContent="space-between" padding={'16px'} gap={2}>
        {isLoading ? (
          <Loading />
        ) : (
          leadStatuses.map((status) => <StatsItem key={status.value} status={status} rows={rows} />)
        )}
      </Grid>
      <TableRender
        data={rows}
        type={'lead'}
        defaultOrderBy={'createdAt'}
        isLoading={isLoading}
        toolbar
        pagination
        densePadding
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageItemName="campaignTableRowsPerPage"
      />
    </BoxWrapper>
  )
}
