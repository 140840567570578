// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PermissionSettings {
  padding: 3% 3%;
  padding-bottom: 0;
  overflow-y: scroll;
  max-height: 80vh;
}
.PermissionSettings .optionsContainer {
  border-bottom: 1px solid #e5e5e5;
  padding: 2% 0;
}
.PermissionSettings .optionsContainer .desc {
  margin-bottom: 1%;
}
.PermissionSettings .optionsContainer .subCheckBox {
  margin-left: 3%;
}`, "",{"version":3,"sources":["webpack://./src/views/accountsAndPermissions/permissionSettings/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,gCAAA;EACA,aAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AADI;EACE,eAAA;AAGN","sourcesContent":[".PermissionSettings {\n  padding: 3% 3%;\n  padding-bottom: 0;\n  overflow-y: scroll;\n  max-height: 80vh;\n  .optionsContainer {\n    border-bottom: 1px solid #e5e5e5;\n    padding: 2% 0;\n    .desc {\n      margin-bottom: 1%;\n    }\n    .subCheckBox {\n      margin-left: 3%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
