/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo } from 'react'
import dayjs, { OpUnitType } from 'dayjs'
import { Line, LineChart, ResponsiveContainer } from 'recharts'
import PlaceHolderRender from '../../../components/PlaceHolderRender'
import './styles.scss'

interface Props {
  name: string
  value: number
}
interface Counter {
  day: number
  week: number
  month: number
}
const LeadsPerMonth = ({ leads }) => {
  const [graph, setGraph] = React.useState<Props[]>()
  const [counts, setCounts] = React.useState<Counter>({ day: 0, week: 0, month: 0 })

  const countItemsByMonth = useCallback((items) => {
    if (!items) {
      return
    }

    const countsByMonth: Props[] = []

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const date = new Date(item.createdTime)
      const month = date.toLocaleString('de-DE', { month: 'short' })
      const index = countsByMonth.length
        ? countsByMonth.findIndex((mont) => mont.name === month)
        : -1

      if (index >= 0) {
        countsByMonth[index] = { ...countsByMonth[index], value: countsByMonth[index].value + 1 }
      } else {
        countsByMonth.push({ name: month, value: 1 })
      }
    }

    return countsByMonth
  }, [])

  const countInWeek = (items: any) => {
    if (items && items.length) {
      return items.filter((item: any) => {
        const itemDate = dayjs(item?.createdTime)
        return itemDate.isSame(dayjs(), 'week')
      })
    }

    return []
  }

  function filterDate(items: any[], filterBy: OpUnitType | undefined) {
    const currentDate = dayjs()

    let filteredItems: any = []
    if (items && items.length) {
      filteredItems = items.filter((item) => {
        const itemDate = dayjs(item?.createdTime)
        return itemDate.isSame(currentDate, filterBy)
      })
    }
    return filteredItems
  }

  useEffect(() => {
    setGraph(countItemsByMonth(filterDate(leads, 'year')))
  }, [countItemsByMonth, leads])

  useMemo(() => {
    const day = filterDate(leads, 'day')?.length
    const week = countInWeek(leads)?.length
    const month = filterDate(leads, 'month')?.length
    setCounts({ day, week, month })
  }, [leads])

  return (
    <>
      {graph?.length ? (
        <div className="leadsPerMonthGraph">
          <ResponsiveContainer aspect={1} maxHeight={100}>
            <LineChart width={300} height={100} data={graph}>
              <Line type="monotone" dataKey="value" stroke="#220d77" strokeWidth={2} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <PlaceHolderRender box text="No leads in this month" />
      )}
      <div className="stats">
        <div className="item">
          <p>Today</p>
          <span>{counts.day}</span>
        </div>
        <div className="item">
          <p>This week</p>
          <span>{counts.week}</span>
        </div>
        <div className="item">
          <p>This month</p>
          <span>{counts.month}</span>
        </div>
      </div>
    </>
  )
}

export default LeadsPerMonth
