// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monthlyTask {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.monthlyTask .taskInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.monthlyTask .taskInfo .taskTitle {
  color: #434243;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.monthlyTask .taskInfo .taskType {
  margin: 0;
  margin-top: 4px;
  color: #757575;
  font-size: 12px;
  font-weight: 400;
}
.monthlyTask .taskValue {
  display: flex;
  color: #434243;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.monthlyTask .company {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #e9e7f2;
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/MonthlyTasks/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,uBAAA;EACA,WAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AAGN;AADI;EACE,SAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAGN;AAAE;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AAEJ;AAAE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".monthlyTask {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  .taskInfo {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    width: 100%;\n    .taskTitle {\n      color: #434243;\n      font-size: 14px;\n      font-weight: 500;\n      margin: 0;\n    }\n    .taskType {\n      margin: 0;\n      margin-top: 4px;\n      color: #757575;\n      font-size: 12px;\n      font-weight: 400;\n    }\n  }\n  .taskValue {\n    display: flex;\n    color: #434243;\n    font-family: Roboto;\n    font-size: 14px;\n    font-weight: 500;\n    margin: 0;\n  }\n  .company {\n    display: flex;\n    width: 32px;\n    height: 32px;\n    padding: 8px 11px;\n    justify-content: center;\n    align-items: center;\n    border-radius: 4px;\n    background: #e9e7f2;\n    margin-right: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
