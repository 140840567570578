import React, { useMemo } from 'react'
import { Checkbox, IconButton, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import EnhancedTableCell from '../EnhancedTableCell/EnhancedTableCell'
import '../../styles.scss'
import AdvancedMenu from '../../../AdvancedMenu/AdvancedMenu'
import { useSelector } from '../../../../store/store'
import { hasPermission } from '../../../../utils/tools'
import { isCustomer, USER_ROLES } from '../../../../constants'

const CampaignRow = ({ row, labelId = '', isItemSelected = false, checkbox = false }) => {
  const navigate = useNavigate()

  const { id, campaign, pageName, createdTime, totalLeads, customer } = row

  const { role } = useSelector((state) => state.user.userInfo)
  const { permissions } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.campaignsDetailsFields)

  const handleClick = () => {
    navigate(`/campaigns/${id}`)
  }

  // Here we define TableCell
  const TableCellCampaign = (
    props: JSX.IntrinsicAttributes & {
      children: React.ReactNode
      action?: boolean | undefined
      checkbox?: boolean | undefined
      handleClick?: (() => void) | undefined
    }
  ) => {
    return (
      <EnhancedTableCell id={id} handleClick={handleClick} {...props}>
        {props.children}
      </EnhancedTableCell>
    )
  }

  const customerRoleIsDirect = useMemo(
    () => role === USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value,
    [role]
  )

  const isActive = row.status === 'ACTIVE'

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      sx={{
        cursor: 'pointer',
        '*': {
          color: isActive ? 'ActiveCaption' : 'GrayText',
        },
      }}
    >
      {checkbox && (
        <TableCellCampaign checkbox action>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCellCampaign>
      )}
      {permission('customer') && !customerRoleIsDirect && (
        <TableCellCampaign>
          <div className="monthlyTask">
            <div className="company">{customer ? customer?.charAt(0).toUpperCase() : 'C'}</div>
            <div className="taskInfo">
              <p className="taskTitle">{customer || 'N/A'}</p>
            </div>
          </div>
        </TableCellCampaign>
      )}
      {permission('page') && !isCustomer(role) && <TableCellCampaign>{pageName}</TableCellCampaign>}
      {permission('name') && <TableCellCampaign>{campaign}</TableCellCampaign>}
      {permission('createdTime') && (
        <TableCellCampaign>{dayjs(createdTime).format('DD/MM/YYYY')}</TableCellCampaign>
      )}
      {permission('totalLeadsCount') && <TableCellCampaign>{totalLeads}</TableCellCampaign>}

      <TableCellCampaign action>
        <IconButton disabled>
          <AdvancedMenu id={id} type={'lead'} />
        </IconButton>
      </TableCellCampaign>
    </TableRow>
  )
}

export default CampaignRow
