import useSWR, { SWRResponse } from 'swr'
import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import {
  AppBar,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import commonCodebase from 'common-recruspin-codebase'
import { useQuery } from '@apollo/client'
import { IconCircleX } from '@tabler/icons-react'
import { useDropzone } from 'react-dropzone'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import queries from '../../../graphQL/query'
import FileUploadArea from '../../../ui-component/fileUpload/FileUploadArea'
import FormErrorsModal from '../../../ui-component/FormErrorsModal'
import NoProvidedCustomerEmail from '../../../ui-component/NoProvidedCustomerEmail'
import { setTriggerOrdersRefetch } from '../../../store/slices/general'
import { useDispatch, useSelector } from '../../../store/store'
import AlertModal from '../../../ui-component/AlertModal'
import axiosInstance from '../../../utils/axiosInstance'
import { makeMultipartFormData } from '../../../utils/tools'
import {
  Iassignee,
  Iselect,
  allowedDocumentFileTypes,
  allowedImageFileTypes,
  allowedPreviewFileTypes,
  allowedVideoFileTypes,
  genders,
} from '../../../constants'
import Loading from '../../../ui-component/loading/Loading'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TresolvedAny } from '../../../types'
import { TunsolvedAny } from '../../../types/unsolved'
import SuccessModal from '../../../ui-component/SuccessModal'

const { statuses, channels, types } = commonCodebase.orders

interface Iprops {
  handleClose: () => void
}

interface IdAndName {
  id: string
  name: string
}

const CreateOrder: React.FC<Iprops> = ({ handleClose }) => {
  const [previewFiles, setPreviewFiles] = useState<null | File[]>(null)
  const [imageAssetsFiles, setImageAssetsFiles] = useState<null | File[]>(null)
  const [invoiceFiles, setInvoiceFiles] = useState<null | File[]>(null)
  const [videoAssets, setVideoAssets] = useState<null | File[]>(null)
  const [assignees, setAssignees] = useState([{ firstName: '', lastName: '', id: '' }])
  const [customers, setCustomers] = useState([{ name: '', id: '', emails: null }])
  const [createTypebotCampaign, setCreateTypebotCampaign] = useState(false)
  const [typebotTemplates, setTypebotTemplates] = useState<IdAndName[]>([])
  const [typebotTemplateId, setTypebotTemplateId] = useState('')
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [mutationError, setMutationError] = useState<null | [string]>(null)
  const [loadingMutation, setLoadingMutation] = useState(false)
  const [open, setOpen] = useState(false)
  const [isAllowed, setIsAllowed] = useState(false)
  const [error, setError] = useState('')

  const { data: fetchedAssignees } = useQuery(queries.assignees)
  const { data: fetchedCustomers, refetch } = useQuery(queries.customerNames)
  const { data: fetchedCampaigns, isLoading: loadingCampaigns }: SWRResponse = useSWR(
    '/campaignIdsAndNames',
    axiosInstance
  )
  const campaigns = fetchedCampaigns?.data.campaigns
  const { data: fetchedTypebotTemplates }: SWRResponse = useSWR('/typebotTemplates', axiosInstance)
  const dispatch = useDispatch()
  const { triggerCustomersRefetch } = useSelector((state) => state.general)

  useEffect(() => {
    if (triggerCustomersRefetch) {
      refetch()
      dispatch(setTriggerOrdersRefetch(false))
    }
  }, [dispatch, refetch, triggerCustomersRefetch])

  useEffect(() => {
    if (fetchedAssignees) setAssignees(fetchedAssignees.appUsers)
    if (fetchedCustomers) setCustomers(fetchedCustomers.customers)
    if (fetchedTypebotTemplates) setTypebotTemplates(fetchedTypebotTemplates.data.templates)
  }, [fetchedAssignees, fetchedCustomers, fetchedTypebotTemplates])
  useEffect(() => {
    if (mutationError) {
      setFormikErrors(mutationError)
      setErrorsModalOpen(true)
    }
  }, [mutationError])

  const checkFileFormat = (acceptedFiles: File[], allowedTypes: string[], errorMessage: string) => {
    const incomingTypes = acceptedFiles.map((file) => file.type)
    const isAllowed = incomingTypes.every((type) => allowedTypes.includes(type))
    if (!isAllowed) {
      setError(errorMessage)
      setIsAllowed(isAllowed)
      setOpen(true)
      throw new Error(errorMessage)
    }
  }

  const handleCloseAlert = () => {
    setOpen((prev) => !prev)
  }
  const onDropPreviewFiles = useCallback((acceptedFiles: File[]) => {
    checkFileFormat(
      acceptedFiles,
      Object.values(allowedPreviewFileTypes.file),
      'File format is not supported'
    )
    setPreviewFiles(acceptedFiles)
  }, [])
  const onDropImageAssetsFiles = useCallback((acceptedFiles: File[]) => {
    checkFileFormat(
      acceptedFiles,
      Object.values(allowedImageFileTypes.image),
      'File format is not supported'
    )
    setImageAssetsFiles(acceptedFiles)
  }, [])
  const onDropInvoiceFiles = useCallback((acceptedFiles: File[]) => {
    checkFileFormat(
      acceptedFiles,
      Object.values(allowedDocumentFileTypes.document),
      'File format is not supported'
    )
    setInvoiceFiles(acceptedFiles)
  }, [])
  const onDropVideoAssetsFiles = useCallback((acceptedFiles: File[]) => {
    checkFileFormat(
      acceptedFiles,
      Object.values(allowedVideoFileTypes.video),
      'File format is not supported'
    )
    setVideoAssets(acceptedFiles)
  }, [])

  const {
    acceptedFiles: acceptedFilesPreviews,
    getRootProps: getRootPropsPreviews,
    getInputProps: getInputPropsPreviews,
    isDragActive: isDragActivePreviews,
  } = useDropzone({
    onDrop: onDropPreviewFiles,
  })

  const {
    acceptedFiles: acceptedFilesImageAssets,
    getRootProps: getRootPropsImageAssets,
    getInputProps: getInputPropsImageAssets,
    isDragActive: isDragActiveImageAssets,
  } = useDropzone({
    onDrop: onDropImageAssetsFiles,
  })

  const {
    acceptedFiles: acceptedFilesInvoice,
    getRootProps: getRootPropsInvoice,
    getInputProps: getInputPropsInvoice,
    isDragActive: isDragActiveInvoice,
  } = useDropzone({
    onDrop: onDropInvoiceFiles,
  })

  const {
    acceptedFiles: acceptedFilesVideoAssets,
    getRootProps: getRootPropsVideoAssets,
    getInputProps: getInputPropsVideoAssets,
    isDragActive: isDragActiveVideoAssets,
  } = useDropzone({
    onDrop: onDropVideoAssetsFiles,
  })

  const validationSchema = yup.object({
    customer: yup
      .string()
      .required()
      .oneOf(customers.map((i) => i.id))
      .default(customers.map((i) => i.id)[0]),
    orderType: yup.string().required(),
    place: yup.string().required().min(2, 'Place should be of minimum 2 characters length'),
    assignee: yup
      .string()
      .oneOf(assignees.map((i) => i.id))
      .default(assignees.map((i) => i.firstName)[0])
      .required(),
    jobName: yup.string().required().min(2, 'Job title should be of minimum 2 characters length'),
    status: yup
      .string()
      .required()
      .oneOf(statuses.map((i) => i.value))
      .default(''),
    genders: yup.string().required().oneOf(genders).required().default(genders[0]),
    channel: yup.array().required(),
    jobAdLink: yup.string().required().min(5, 'Link should be of minimum 5 characters length'),
    externalImportId: yup.string().nullable(),
    metaCampaign: yup
      .string()
      .oneOf(campaigns ? campaigns?.map((i: { id: string; name: string }) => i.id) : [])
      .nullable(),
    keywords: yup.string().nullable(),
    comments: yup.string().max(1000, 'Maximum 1000 Charachters allowed'),
    budget: yup.number().min(0).required(),
    price: yup.number().min(0).required(),
    runtime: yup.number().min(0).required(),
    previews: yup.object().nullable(),
    imageAssets: yup.object().nullable(),
    invoices: yup.object().nullable(),
    videoAssets: yup.object().nullable(),
  })

  const formik = useFormik({
    initialValues: {
      customer: customers.map((i) => i.id)[0],
      assignee: assignees.map((i) => i.id)[0],
      jobName: '',
      status: statuses.map((i) => i.value)[0],
      gender: genders[0],
      place: '',
      channel: [],
      orderType: '',
      jobAdLink: '',
      externalImportId: '',
      metaCampaign: undefined,
      keywords: '',
      comments: '',
      budget: '',
      price: '',
      runtime: '',
      previews: undefined,
      previewsVideo: undefined,
      previewsAudio: undefined,
      imageAssets: undefined,
      invoices: undefined,
      videoAssets: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      const newFormData: TunsolvedAny = {
        jobName: values.jobName,
        jobType: values.orderType,
        gender: values.gender,
        place: values.place,
        channel: values.channel,
        budget: String(values.budget),
        price: String(values.price),
        runtime: values.runtime,
        jobAdLink: values.jobAdLink,
        externalImportId: values.externalImportId,
        keywords: values.keywords,
        comments: values.comments,
        status: values.status,
        assignee: {
          connect: { id: values.assignee },
        },
        customer: {
          connect: { id: values.customer },
        },
        previews: values.previews,
        previewsAudio: values.previewsAudio,
        previewsVideo: values.previewsVideo,
        imageAssets: values.imageAssets,
        invoices: values.invoices,
        videoAssets: values.videoAssets,
      }

      if (values.metaCampaign) {
        newFormData.metaCampaign = {
          connect: { id: values.metaCampaign },
        }
      } else {
        newFormData.metaCampaign = undefined
      }

      const mapFileFields = [
        { name: 'previews', source: 'image' },
        { name: 'previewsAudio', source: 'image' },
        { name: 'previewsVideo', source: 'image' },
        { name: 'imageAssets', source: 'image' },
        { name: 'invoices', source: 'file' },
        { name: 'videoAssets', source: 'file' },
      ]

      const formData = makeMultipartFormData(mapFileFields, newFormData)

      try {
        setLoadingMutation(true)
        await axiosInstance.post(
          `/orders/create?createTypebotCampaign=${createTypebotCampaign}&typebotTemplateId=${typebotTemplateId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data;',
            },
          }
        )
        setSuccessModalOpen(true)
        dispatch(setTriggerOrdersRefetch(true))
      } catch (error: TresolvedAny) {
        setMutationError([error.response.statusText])
      } finally {
        setLoadingMutation(false)
      }
    },
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const errors = Object.values(formik.errors)
    if (errors.length) {
      setErrorsModalOpen(true)
      setFormikErrors(errors as string[])
      return
    }

    await handleFormFiles()

    formik.handleSubmit()

    async function handleFormFiles() {
      const mapFile = (file: File, uploadKey: string) => ({
        name: file.name,
        [uploadKey]: {
          upload: file,
        },
      })

      const handleFiles = async (field: string, files: File[] | null, uploadKey: string) => {
        if (files) {
          const mappedFiles = files.map((file: File) => mapFile(file, uploadKey))
          await formik.setFieldValue(field, { create: mappedFiles })
        }
      }

      const handlePreviewFiles = async (files: File[] | null, uploadKey: string) => {
        if (files?.length) {
          interface MapFileInterface {
            name: string
          }
          const audio: MapFileInterface[] = []
          const video: MapFileInterface[] = []
          const image: MapFileInterface[] = []

          files.forEach((file: File) => {
            if (file.type.includes('audio')) {
              audio.push(mapFile(file, uploadKey))
            }
            if (file.type.includes('video')) {
              video.push(mapFile(file, uploadKey))
            }
            if (file.type.includes('image')) {
              image.push(mapFile(file, uploadKey))
            }
          })

          await Promise.all([
            formik.setFieldValue('previewsAudio', { create: audio.length ? audio : [] }),
            formik.setFieldValue('previewsVideo', { create: video.length ? video : [] }),
            formik.setFieldValue('previews', { create: image.length ? image : [] }),
          ])
        }
      }

      await Promise.all([
        handlePreviewFiles(previewFiles, 'image'),
        handleFiles('imageAssets', imageAssetsFiles, 'image'),
        handleFiles('invoices', invoiceFiles, 'file'),
        handleFiles('videoAssets', videoAssets, 'file'),
      ])
    }
  }

  if (loadingMutation) return <Loading />

  return (
    <div className="CreateOrder">
      <form onSubmit={(e) => handleSubmit(e)}>
        <AppBar sx={{ position: 'sticky' }} color="default">
          {AppBarToolbar()}
        </AppBar>
        {FormContainer()}
        <FormErrorsModal
          errorsModalOpen={errorsModalOpen}
          formikErrors={formikErrors}
          setErrorsModalOpen={setErrorsModalOpen}
        />
        <SuccessModal
          successModalOpen={successModalOpen}
          handleClose={handleClose}
          setSuccessModalOpen={setSuccessModalOpen}
          message={<FormattedMessage id="created-successfully" />}
        />
      </form>
      {!isAllowed && (
        <AlertModal message={error} isAlert alertModalOpen={open} handleClose={handleCloseAlert} />
      )}
    </div>
  )

  function FormContainer() {
    return (
      <div className="formContainer">
        <Container maxWidth="md" className="customerDetails subFormContainer">
          <div className="header">
            <FormattedMessage id="customer-details" />
          </div>
          <Grid container className="content">
            <FormControl fullWidth>
              <InputLabel id="customer">
                <FormattedMessage id="customer" />
              </InputLabel>
              <Select
                fullWidth
                labelId="customer"
                id="customer"
                name="customer"
                value={formik.values.customer}
                label={<FormattedMessage id="customer" />}
                onChange={formik.handleChange}
              >
                {customers.map((i: { name: string; id: string; emails: string | null }) => {
                  const customerHasEmail = i.emails ? JSON.parse(i.emails).length : null

                  return (
                    <MenuItem key={i?.id} value={i?.id}>
                      {i.name}
                      {!customerHasEmail ? (
                        <NoProvidedCustomerEmail iconSize="16px" buttonSize="small" />
                      ) : null}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="assignee">
                <FormattedMessage id="assignee" />
              </InputLabel>
              <Select
                fullWidth
                labelId="assignee"
                id="assignee"
                name="assignee"
                value={formik.values.assignee}
                label={<FormattedMessage id="assignee" />}
                onChange={formik.handleChange}
              >
                {assignees.map((i: Iassignee) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.firstName} {i.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Container>
        <Container maxWidth="md" className="jobDetails subFormContainer">
          <div className="header">
            <FormattedMessage id="job-details" />
          </div>
          <Grid container className="content">
            <TextField
              fullWidth
              label={<FormattedMessage id="job-title" />}
              id="jobName"
              name="jobName"
              placeholder="Job title"
              type="jobName"
              value={formik.values.jobName}
              onChange={formik.handleChange}
              error={formik.touched.jobName && Boolean(formik.errors.jobName)}
            />
            <FormControl fullWidth>
              <InputLabel id="status">
                <FormattedMessage id="status" />
              </InputLabel>
              <Select
                fullWidth
                labelId="status"
                id="status"
                name="status"
                value={formik.values.status}
                label={<FormattedMessage id="status" />}
                onChange={formik.handleChange}
              >
                {statuses.map((i: Iselect) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="orderType">
                <FormattedMessage id="order-type" />
              </InputLabel>
              <Select
                fullWidth
                labelId="orderType"
                id="orderType"
                name="orderType"
                value={formik.values.orderType}
                label={<FormattedMessage id="order-type" />}
                onChange={formik.handleChange}
              >
                {types.map((i: Iselect) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="gender">
                <FormattedMessage id="gender" />
              </InputLabel>
              <Select
                fullWidth
                labelId="gender"
                id="gender"
                name="gender"
                value={formik.values.gender}
                label={<FormattedMessage id="gender" />}
                onChange={formik.handleChange}
              >
                {genders.map((i: string) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={<FormattedMessage id="place" />}
              id="place"
              name="place"
              placeholder="Place"
              type="place"
              value={formik.values.place}
              onChange={formik.handleChange}
              error={formik.touched.place && Boolean(formik.errors.place)}
            />
            <FormControl fullWidth>
              <InputLabel id="channel">
                <FormattedMessage id="portal" />
              </InputLabel>
              <Select
                fullWidth
                multiple
                labelId="channel"
                id="channel"
                name="channel"
                value={formik.values.channel}
                label={<FormattedMessage id="portal" />}
                onChange={formik.handleChange}
              >
                {channels.map((i: Iselect) => (
                  <MenuItem key={i.value} value={i.value}>
                    <Chip variant="outlined" key={i.value} label={i.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={<FormattedMessage id="job-ad-link" />}
              id="jobAdLink"
              name="jobAdLink"
              placeholder="Link to job ad"
              type="text"
              value={formik.values.jobAdLink}
              onChange={formik.handleChange}
              error={formik.touched.jobAdLink && Boolean(formik.errors.jobAdLink)}
            />
            <TextField
              fullWidth
              label={<FormattedMessage id="external-import-id" />}
              id="externalImportId"
              name="externalImportId"
              placeholder="External import id"
              type="text"
              value={formik.values.externalImportId}
              onChange={formik.handleChange}
              error={formik.touched.externalImportId && Boolean(formik.errors.externalImportId)}
            />

            <TextField
              fullWidth
              label={<FormattedMessage id="keywords" />}
              id="keywords"
              name="keywords"
              placeholder="Keywords"
              type="text"
              value={formik.values.keywords}
              onChange={formik.handleChange}
              error={formik.touched.keywords && Boolean(formik.errors.keywords)}
            />

            {loadingCampaigns ? (
              <Loading />
            ) : (
              <Autocomplete
                fullWidth
                options={campaigns}
                getOptionLabel={(option: IdAndName) => option.name}
                onChange={(e, value) => {
                  formik.setFieldValue('metaCampaign', value?.id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="metaCampaign" />}
                    id="metaCampaign"
                    name="metaCampaign"
                    placeholder="Meta Campaign"
                    type="text"
                    value={formik.values.metaCampaign}
                    onChange={formik.handleChange}
                    error={formik.touched.metaCampaign && Boolean(formik.errors.metaCampaign)}
                  />
                )}
              />
            )}

            <div className="typebot">
              <FormControlLabel
                className="form"
                value="start"
                control={
                  <Checkbox
                    checked={createTypebotCampaign}
                    onChange={() => setCreateTypebotCampaign(!createTypebotCampaign)}
                  />
                }
                label="Create Typbot campaign"
                labelPlacement="start"
              />

              {createTypebotCampaign && (
                <FormControl fullWidth>
                  <InputLabel id="template">
                    <FormattedMessage id="template" />
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="template"
                    id="template"
                    name="template"
                    value={typebotTemplateId}
                    label={<FormattedMessage id="template" />}
                    onChange={(e) => setTypebotTemplateId(e.target.value)}
                  >
                    {typebotTemplates.map((i: { name: string; id: string }) => {
                      return (
                        <MenuItem key={i?.id} value={i?.id}>
                          {i.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
              <div></div>
            </div>
          </Grid>
        </Container>
        <Container maxWidth="md" className="comments subFormContainer">
          <div className="header">
            <FormattedMessage id="comments" />
          </div>
          <Grid container className="content">
            <TextField
              fullWidth
              id="comments"
              name="comments"
              placeholder="comments"
              rows={3}
              multiline
              type="text"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
            />
          </Grid>
        </Container>
        <Container maxWidth="md" className="budgetAndInvoice subFormContainer">
          <div className="header">
            <FormattedMessage id="budgets-invoice" />
          </div>
          <Grid container className="content">
            <TextField
              fullWidth
              label={<FormattedMessage id="budget" />}
              id="budget"
              name="budget"
              placeholder="budget"
              type="number"
              value={formik.values.budget}
              onChange={formik.handleChange}
              error={formik.touched.budget && Boolean(formik.errors.budget)}
            />
            <TextField
              fullWidth
              label={<FormattedMessage id="price" />}
              id="price"
              name="price"
              placeholder="Price"
              type="number"
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
            />
            <TextField
              fullWidth
              label={<FormattedMessage id="runtime" />}
              id="runtime"
              name="runtime"
              placeholder="runtime"
              type="number"
              value={formik.values.runtime}
              onChange={formik.handleChange}
              error={formik.touched.runtime && Boolean(formik.errors.runtime)}
              // helperText={formik.touched.name && formik.errors.name}
              InputProps={{
                endAdornment: <InputAdornment position="start">days</InputAdornment>,
              }}
            />
          </Grid>
          <div className="content">
            <FileUploadArea
              title="Invoices"
              getInputProps={getInputPropsInvoice}
              getRootProps={getRootPropsInvoice}
              acceptedFiles={acceptedFilesInvoice}
              isDragActive={isDragActiveInvoice}
              withResults
              supportedFormats={Object.keys(allowedDocumentFileTypes.document)}
            />
          </div>
        </Container>
        <Container maxWidth="md" className="files subFormContainer">
          <div className="header">
            <FormattedMessage id="previews" />
          </div>
          <div className="content">
            <FileUploadArea
              title="Previews"
              getInputProps={getInputPropsPreviews}
              getRootProps={getRootPropsPreviews}
              acceptedFiles={acceptedFilesPreviews}
              isDragActive={isDragActivePreviews}
              withResults
              supportedFormats={Object.keys(allowedPreviewFileTypes.file)}
            />
          </div>
        </Container>
        <Container maxWidth="md" className="files subFormContainer">
          <div className="header">
            <FormattedMessage id="customer-image-assets" />
          </div>
          <div className="content">
            <FileUploadArea
              title="Customer image assets"
              getInputProps={getInputPropsImageAssets}
              getRootProps={getRootPropsImageAssets}
              acceptedFiles={acceptedFilesImageAssets}
              isDragActive={isDragActiveImageAssets}
              withResults
              supportedFormats={Object.keys(allowedImageFileTypes.image)}
            />
          </div>
        </Container>
        <Container maxWidth="md" className="files subFormContainer">
          <div className="header">
            <FormattedMessage id="video-assets" />
          </div>
          <div className="content">
            <FileUploadArea
              title="Video assets"
              getInputProps={getInputPropsVideoAssets}
              getRootProps={getRootPropsVideoAssets}
              acceptedFiles={acceptedFilesVideoAssets}
              isDragActive={isDragActiveVideoAssets}
              withResults
              supportedFormats={Object.keys(allowedVideoFileTypes.video)}
            />
          </div>
        </Container>
      </div>
    )
  }

  function AppBarToolbar() {
    return (
      <Toolbar>
        <IconButton
          size="medium"
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <IconCircleX size="50px" color="gray" />
        </IconButton>
        <Typography sx={{ ml: 3, flex: 1, textAlign: 'center' }} variant="h3" component="div">
          <FormattedMessage id="create-new-order" />
        </Typography>
        <Button variant="contained" color="secondary" autoFocus size="large" type="submit">
          <FormattedMessage id="create" />
        </Button>
      </Toolbar>
    )
  }
}

export default CreateOrder
