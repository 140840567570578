import React, { useCallback, useState } from 'react'
import { Container } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { DropzoneState, useDropzone } from 'react-dropzone'

import './style.scss'
import FileUploadArea from '../fileUpload/FileUploadArea'
import { FileViewRow } from '../fileViewRow/FileViewRow'
import AlertModal from '../AlertModal'
import { IdataSourceItem, IfileItem } from '../../types/other'

type SetStateCb = (files: IfileItem[]) => IfileItem[]

interface IProps {
  dataSources: IdataSourceItem[]
  setDataSources: (dataSources: IdataSourceItem[]) => void
  title: string
  setFiles: ((fn: SetStateCb) => void) & ((files: IfileItem[]) => void)
  idsToDelete: string[]
  setIdsToDelete: (ids: string[]) => void
  idsToCreate: string[]
  setIdsToCreate: (ids: string[]) => void
  hasPreview?: boolean
  isVideo?: boolean
  isMultiType?: boolean
  showButton?: boolean
  allowedTypes?: any
  headerSlot?: React.ElementType
  fileAmountLimit?: number
}

const MultiImageEdit: React.FC<IProps> = ({
  dataSources,
  title,
  setFiles,
  setDataSources,
  setIdsToDelete,
  idsToDelete,
  hasPreview = true,
  allowedTypes,
  showButton = true,
  headerSlot: HeaderSlot,
  fileAmountLimit,
}) => {
  const [open, setOpen] = useState(false)
  const [isAllowed, setIsAllowed] = useState(false)
  const [error, setError] = useState('')

  const handleClose = () => {
    setOpen((prev) => !prev)
  }

  const checkFileAmountLimit = useCallback(
    (filesLength: number, fileAmountLimit: number, errorMessage: string) => {
      if (filesLength > fileAmountLimit) {
        setError(errorMessage)
        setIsAllowed(isAllowed)
        setOpen((prev) => !prev)
        return false
      }
      return true
    },
    [setError, setIsAllowed, setOpen, isAllowed]
  )

  const checkFileFormat = useCallback(
    (acceptedFiles: { type: string }[], allowedTypes: string[], errorMessage: string) => {
      acceptedFiles
      const incomingTypes = acceptedFiles.map((file) => file.type)
      const isAllowed = incomingTypes.every((type) => allowedTypes.includes(type))
      if (!isAllowed) {
        setError(errorMessage)
        setIsAllowed(false)
        setOpen((prev) => !prev)
        throw new Error(errorMessage)
      }
    },
    [setError, setIsAllowed, setOpen]
  )

  const onDropFile = useCallback(
    (addedFiles: File[]) => {
      if (addedFiles?.length) {
        allowedTypes &&
          checkFileFormat(addedFiles, Object.values(allowedTypes), 'File format is not supported')

        let passed = true
        const newFiles = addedFiles.map((file) => ({
          id: uuidv4(),
          file,
          name: file.name,
          url: URL.createObjectURL(file),
        }))

        setFiles((prevFiles) => {
          const files = [...prevFiles, ...newFiles]

          if (fileAmountLimit) {
            passed = checkFileAmountLimit(
              files.length,
              fileAmountLimit,
              `You can only upload ${fileAmountLimit} file(s) at a time`
            )
          }
          return passed ? files : [...prevFiles]
        })

        if (!passed) return

        const newDataSource = newFiles.map((file) => ({
          id: file.id,
          name: file.name,
          url: file.url,
        }))
        setDataSources([...dataSources, ...newDataSource])
      }
    },
    [
      allowedTypes,
      checkFileFormat,
      setFiles,
      setDataSources,
      dataSources,
      fileAmountLimit,
      checkFileAmountLimit,
    ]
  )

  const { acceptedFiles, getRootProps, getInputProps, isDragActive }: DropzoneState = useDropzone({
    onDrop: onDropFile,
  })

  return (
    <Container disableGutters className="MultiImageEdit">
      <div className="uploadArea">
        <FileUploadArea
          title={title}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          acceptedFiles={acceptedFiles}
          isDragActive={isDragActive}
          showButton={showButton}
          supportedFormats={allowedTypes ? Object.keys(allowedTypes) : undefined}
        />
      </div>

      {HeaderSlot && <HeaderSlot dataSources={dataSources} />}
      {dataSources.map((dataSource) => (
        <FileViewRow
          key={dataSource.id}
          fileName={dataSource.name}
          fileSource={dataSource.url}
          hasPreview={hasPreview}
          onRemove={() => {
            setIdsToDelete([...idsToDelete, dataSource.id])
            setDataSources(dataSources.filter((item) => item.id !== dataSource.id))
            setFiles((prevFiles: IfileItem[]) =>
              prevFiles.filter((file: IfileItem) => file.id !== dataSource.id)
            )
          }}
        />
      ))}
      {!isAllowed && (
        <AlertModal message={error} isAlert alertModalOpen={open} handleClose={handleClose} />
      )}
    </Container>
  )
}

export default MultiImageEdit
