import type { FC } from 'react'
import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { BrokenImage } from '@mui/icons-material'
import useSWR, { SWRResponse } from 'swr'
import type { AdsTableProps } from './AdsTable.types'
import axiosInstance from '../../../../../../../../../../utils/axiosInstance'
import { COLUMNS } from './AdsTable.constants'

const formatNumber = (number: number | string) => Number(number).toFixed(3)

const AdsTable: FC<AdsTableProps> = ({ adSetId }) => {
  const { data, isLoading }: SWRResponse = useSWR(`/meta/ads/${adSetId}`, axiosInstance)

  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          {COLUMNS.map(({ name }) => (
            <TableCell key={name}>{name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && (
          <TableRow key="loader">
            <TableCell colSpan={10}>
              <LinearProgress style={{ width: '100%' }} />
            </TableCell>
          </TableRow>
        )}
        {data?.data?.map(
          ({
            id,
            name,
            ctr,
            creative,
            linkClicks,
            frequency,
            clicks,
            impressions,
            avgPlaybackTime,
          }) => {
            const linkCTR = linkClicks ? formatNumber((linkClicks / impressions) * 100) : 'N/A'

            return (
              <TableRow key={id}>
                <TableCell>
                  {creative?.thumbnailURL ? (
                    <img
                      src={creative.thumbnailURL}
                      alt={name ?? 'Ad Thumbnail'}
                      onError={(e) => {
                        e.currentTarget.style.display = 'none'
                      }}
                      loading="lazy"
                      style={{ width: '50px', height: 'auto' }}
                    />
                  ) : (
                    <BrokenImage style={{ width: '50px', height: 'auto' }} />
                  )}
                </TableCell>
                <TableCell>{name ?? 'N/A'}</TableCell>
                <TableCell>{clicks ?? 'N/A'}</TableCell>
                <TableCell>{linkClicks ?? 'N/A'}</TableCell>
                <TableCell>{linkCTR ?? 'N/A'}</TableCell>
                <TableCell>{impressions ?? 'N/A'}</TableCell>
                <TableCell>{(ctr && formatNumber(ctr)) ?? 'N/A'}</TableCell>
                <TableCell>{(frequency && formatNumber(frequency)) ?? 'N/A'}</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>{avgPlaybackTime ?? 'N/A'}</TableCell>
              </TableRow>
            )
          }
        )}
      </TableBody>
    </Table>
  )
}

export default AdsTable
