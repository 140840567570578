import useSWR, { SWRResponse } from 'swr'
import commonCodebase from 'common-recruspin-codebase'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Container, Grid, Typography, Link, Box } from '@mui/material'

import './style.scss'
import { FormattedMessage } from 'react-intl'
import { FileViewRow } from '../../../ui-component/fileViewRow/FileViewRow'
import NoProvidedCustomerEmail from '../../../ui-component/NoProvidedCustomerEmail'
import { convertValuesToLabels, hasPermission } from '../../../utils/tools'
import { customerTypes } from '../../customers/create/CreateCustomer'
import { useSelector } from '../../../store/store'
import CopyLinkBtn from './CopyLinkBtn'
import useInternalUserPermissions from '../../../hooks/useInternalUserPermissions'
import JobDetailsMenu from './JobDetailsMenu'
import { Iprops, TfileItem, TimageItem } from './ReadOrder.type'
import axiosInstance from '../../../utils/axiosInstance'

const { statuses } = commonCodebase.orders

const ReadOrder: React.FC<Iprops> = ({ order }) => {
  const { permissions } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.orderDetailsFields)
  const previewFiles = [...order.previews, ...order.previewsAudio, ...order.previewsVideo]
  const customerHasEmail = order.customer.emails ? JSON.parse(order.customer.emails).length : null
  const { isInternalUser } = useInternalUserPermissions()

  const { data: fetchedTypebotLink }: SWRResponse = useSWR(
    `/typebot-public-url/${order.typebotCampaign?.externalId}`,
    axiosInstance
  )
  const publicId = fetchedTypebotLink?.data?.publicId

  return (
    <div className="ReadTask">
      <Container maxWidth="md" className="formContainer">
        <div className="customerDetails subFormContainer">
          <div className="header">
            <FormattedMessage id="customer-details" />
          </div>
          <Grid spacing={2} container className="content">
            {permission('customer') && order?.customer?.name && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">Customer name:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {order?.customer?.name}
                    {!customerHasEmail && <NoProvidedCustomerEmail />}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="customer-type" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {convertValuesToLabels(customerTypes, order?.customer?.type)}
                  </Typography>
                </Grid>
              </>
            )}
            {permission('assignee') && order.assignee?.firstName && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="assignee" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {order.assignee?.firstName && (
                    <Typography variant="body2">
                      {order.assignee.firstName} {order.assignee.lastName}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </div>

        <div className="customerDetails subFormContainer">
          <div className="header">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <span>
                <FormattedMessage id="job-details" />
              </span>
              {isInternalUser && <JobDetailsMenu order={order} />}
            </Box>
          </div>
          <Grid spacing={2} container className="content">
            {permission('jobName') && order.jobName && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="job-title" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.jobName}</Typography>
                </Grid>
              </>
            )}
            {permission('status') && order.status && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="status" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {convertValuesToLabels(statuses, order.status)}
                  </Typography>
                </Grid>
              </>
            )}

            {permission('jobType') && order.jobType && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="order-type" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.jobType}</Typography>
                </Grid>
              </>
            )}

            {permission('gender') && order.gender && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="gender" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.gender}</Typography>
                </Grid>
              </>
            )}

            {permission('place') && order.place && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="place" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.place}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h5">Portal:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.channel.join(', ')}</Typography>
                </Grid>
              </>
            )}

            {permission('jobAdLink') && order.jobAdLink && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="job-ad-link" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.jobAdLink}</Typography>
                </Grid>
              </>
            )}

            {permission('externalImportId') && order.externalImportId && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="external-import-id" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.externalImportId}</Typography>
                </Grid>
              </>
            )}
            {permission('metaCampaign') && order.metaCampaign && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="metaCampaign" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={`/campaigns/${order.metaCampaign.id}`}
                    rel="noreferrer"
                  >
                    <Typography variant="body2">{order.metaCampaign.name}</Typography>
                  </Link>
                </Grid>
              </>
            )}
            {permission('keywords') && order.keywords && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="keywords" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.keywords}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </div>

        {permission('comments') && order.comments && (
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="comments" />
            </div>
            <Grid spacing={2} container className="content">
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="comments" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{order.comments}</Typography>
              </Grid>
            </Grid>
          </div>
        )}

        {permission('typebotCampaign') && order.typebotCampaign && (
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="typebot-campaign" />
            </div>
            <Grid spacing={2} container className="content">
              {publicId && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="address" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      <Link component={RouterLink} target="_blank" to={publicId} rel="noreferrer">
                        {publicId}
                      </Link>
                    </Typography>
                  </Grid>
                </>
              )}
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="edit-url" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    <Link target="_blank" rel="noreferrer" href={order.typebotCampaign?.editUrl}>
                      {order.typebotCampaign?.editUrl}
                    </Link>
                  </Typography>
                </Grid>
              </>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="leads-count" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{order.typebotCampaign.typebotLeadsCount}</Typography>
              </Grid>
            </Grid>
          </div>
        )}

        <div className="customerDetails subFormContainer">
          <div className="header">
            <FormattedMessage id="budgets-invoice" />
          </div>
          <Grid spacing={2} container className="content">
            {permission('budget') && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="budget" />
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.budget}</Typography>
                </Grid>
              </>
            )}

            {permission('price') && order.price && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="price" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.price}</Typography>
                </Grid>
              </>
            )}

            {permission('runtime') && order.runtime && (
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="runtime" />:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{order.runtime}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </div>

        {permission('invoices') && order.invoices[0] && (
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="invoice-files" />
            </div>
            <div className="content content-files">
              {order.invoices.map((invoice: TfileItem) => (
                <FileViewRow
                  key={invoice.file.url}
                  fileName={invoice.name}
                  fileSource={invoice.file.url}
                />
              ))}
            </div>
          </div>
        )}

        {permission('previews') && previewFiles[0] && (
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="preview" />
            </div>
            <div className="sub-header">
              <Typography variant="h5">
                <FormattedMessage id="uploaded-files" />
              </Typography>
              <Box display="flex" alignItems="center" gap="4px">
                <Link component={RouterLink} target="_blank" to={`/order-previews?id=${order.id}`}>
                  <FormattedMessage id="preview-all-link" />
                </Link>
                <CopyLinkBtn str={`/order-previews?id=${order.id}`} />
              </Box>
            </div>
            <div className="content content-files">
              {previewFiles.map((preview: TimageItem) => {
                return (
                  <FileViewRow
                    key={preview?.image.url}
                    hasPreview
                    fileName={preview.name}
                    fileSource={preview?.image.url}
                  />
                )
              })}
            </div>
          </div>
        )}

        {permission('videoAssets') && order.videoAssets[0] && (
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="video-assets" />
            </div>
            <div className="content content-files">
              {order.videoAssets.map((video) => (
                <FileViewRow
                  key={video.file.url}
                  fileName={video.name}
                  fileSource={video.file.url}
                />
              ))}
            </div>
          </div>
        )}

        {permission('imageAssets content-files') && order.imageAssets[0] && (
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="video-assets" />
            </div>
            <div className="content">
              {order.videoAssets.map((asset: TfileItem) => (
                <FileViewRow
                  key={asset.file.url}
                  fileName={asset.name}
                  fileSource={asset.file.url}
                />
              ))}
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default ReadOrder
