import { RouteObject } from 'react-router-dom'
import { lazy } from 'react'
import Loadable from '../ui-component/Loadable'
import Orders from '../views/orders/Orders'
import OrderPreview from '../views/orderPreviews'
import Customers from '../views/customers/Customers'
import ReadEditTask from '../views/orders/readEdit/editOrder/EditOrder'
import ReadEditCustomer from '../views/customers/readEdit/ReadEditCustomer'
import Leads from '../views/leads/Campaigns'
import { LeadsList } from '../views/leads/leadsList/LeadsList'
import AccountsAndPermissions from '../views/accountsAndPermissions/AccountsAndPermissions'
import MainLayout from '../layout/MainLayout'
import OrderPreviewsApproval from '../views/orderPreviewsApproval/OrderPreviewsApproval'
import Page404 from '../views/Page404'
import { PrivateRoute } from '../ui-component'
import { PAGE } from '../constants'
import { Analytics, UserProfile } from '../views'
import { Dashboard as AnalyticsDashboard, PerformanceKPI, CampaignList } from '../views/Analytics'
import Accounts from '../views/accountsAndPermissions/accounts/accounts'
import Jobs from '../views/jobs'

// sample page routing
const Dashboard = Loadable(lazy(() => import('../views/dashboard')))

// ==============================|| MAIN ROUTING ||============================== //

const children: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: PAGE.DASHBOARD.PATH,
    element: <Dashboard />,
  },
  {
    path: PAGE.ORDERS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.ORDERS.ROLES}>
        <Orders />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.JOBS.PATH,
    element: (
      <PrivateRoute allowedRoles={['admin']}>
        <Jobs />
      </PrivateRoute>
    ),
  },
  {
    path: `${PAGE.ORDERS.PATH}/:id`,
    element: (
      <PrivateRoute allowedRoles={PAGE.ORDERS.ROLES}>
        <ReadEditTask />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.ORDER_PREVIEWS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.ORDER_PREVIEWS.ROLES}>
        <OrderPreview />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.APPROVE_ORDER.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.APPROVE_ORDER.ROLES}>
        <OrderPreviewsApproval />
      </PrivateRoute>
    ),
  },
  {
    path: `${PAGE.CAMPAIGNS.PATH}/:id`,
    element: (
      <PrivateRoute allowedRoles={PAGE.CAMPAIGNS.ROLES}>
        <LeadsList />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.USER.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.USER.ROLES}>
        <UserProfile />
      </PrivateRoute>
    ),
    children: [
      { path: 'profile', element: <UserProfile /> },
      { path: 'security', element: <UserProfile /> },
      { path: 'businessInformation', element: <UserProfile /> },
    ],
  },
  {
    path: `${PAGE.CUSTOMERS.PATH}/:id`,
    element: (
      <PrivateRoute allowedRoles={PAGE.CUSTOMERS.ROLES}>
        <ReadEditCustomer />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.CUSTOMERS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.CUSTOMERS.ROLES}>
        <Customers />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.ACCOUNTS_AND_PERMISSIONS.ACCOUNTS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.ACCOUNTS_AND_PERMISSIONS.ROLES}>
        <Accounts />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.ACCOUNTS_AND_PERMISSIONS.PERMISSIONS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.ACCOUNTS_AND_PERMISSIONS.ROLES}>
        <AccountsAndPermissions />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.LEADS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.LEADS.ROLES}>
        <Leads />
      </PrivateRoute>
    ),
  },
  {
    path: PAGE.ANALYTICS.PATH,
    element: (
      <PrivateRoute allowedRoles={PAGE.ANALYTICS.ROLES}>
        <Analytics />
      </PrivateRoute>
    ),
    children: [
      { path: '', element: <CampaignList /> },
      { path: 'dashboard/:id', element: <AnalyticsDashboard /> },
      { path: 'performance/:id', element: <PerformanceKPI /> },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
]

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children,
}

export default MainRoutes
