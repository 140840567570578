// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pieChartLegend {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.piechartWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.piechartWrapper .recharts-responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

.colorItem {
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 12px 0px;
}
.colorItem .name {
  color: #757575;
  margin: 0;
  font-size: 10px;
}
.colorItem .number {
  color: #757575;
  font-size: 10px;
  margin: 0;
}

.colorWrapper {
  display: flex;
  align-items: center;
}

.piechartWrapperPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.piechartWrapperPlaceholder p {
  margin: 0;
  font-size: 24px;
  color: #a4a8b3;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/TasksChart/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAEF;AADE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;AAGJ;;AAAA;EACE,oBAAA;EACA,8BAAA;EACA,6BAAA;EACA,iBAAA;AAGF;AAFE;EACE,cAAA;EACA,SAAA;EACA,eAAA;AAIJ;AAFE;EACE,cAAA;EACA,eAAA;EACA,SAAA;AAIJ;;AADA;EACE,aAAA;EACA,mBAAA;AAIF;;AAFA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAKF;AAJE;EACE,SAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAMJ","sourcesContent":[".pieChartLegend {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.piechartWrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  .recharts-responsive-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: -webkit-fill-available;\n  }\n}\n.colorItem {\n  display: inline-flex;\n  justify-content: space-between;\n  border-bottom: 1px solid #eee;\n  padding: 12px 0px;\n  .name {\n    color: #757575;\n    margin: 0;\n    font-size: 10px;\n  }\n  .number {\n    color: #757575;\n    font-size: 10px;\n    margin: 0;\n  }\n}\n.colorWrapper {\n  display: flex;\n  align-items: center;\n}\n.piechartWrapperPlaceholder {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  p {\n    margin: 0;\n    font-size: 24px;\n    color: #a4a8b3;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
