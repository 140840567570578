interface AtsCapability {
  name: string
  capabilities: string[]
}

interface AtsProviders {
  [key: string]: AtsCapability
}

const atsProviders: AtsProviders = {
  hr4you: {
    name: 'HR4YOU',
    capabilities: ['applicants:push', 'jobs:pull'],
  },
  'concludis-internal': {
    name: 'Concludis Internal',
    capabilities: ['jobs:pull'],
  },
  onlyfy: {
    name: 'Onlyfy',
    capabilities: ['applicants:push', 'jobs:pull'],
  },
}

export default atsProviders
