import React, { useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  CircularProgress,
} from '@mui/material'
import useSWR from 'swr'
import { useNavigate, useSearchParams } from 'react-router-dom'

// project imports
import EnhancedTableHeader from './EnhancedTableHeader'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import JobDetailsModal from './JobDetailsModal'
import axiosInstance from '../../../utils/axiosInstance'
import { Job } from '../../../types/job'

// Define table headers
const headCells = [
  { id: 'externalId', label: 'External ID' },
  { id: 'customer', label: 'Customer' },
  { id: 'title', label: 'Job Title' },
  { id: 'location', label: 'Location' },
  { id: 'externalCreatedAt', label: 'Created at' },
  { id: 'importedAt', label: 'Imported at' },
]

export default function JobsTable() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const { data, isLoading } = useSWR(`/jobs?page=${page + 1}&limit=${rowsPerPage}`, (url) =>
    axiosInstance.get(url).then((res) => res.data)
  )

  const selectedJobId = searchParams.get('jobId')
  const jobs = data?.jobs || []
  const selectedJob = jobs.find((job: Job) => job.id === selectedJobId) || null

  const totalCount = data?.totalCount || 0

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (jobId: string) => {
    setSearchParams({ jobId })
    navigate(`?jobId=${jobId}`, { replace: true })
  }

  const handleCloseModal = () => {
    setSearchParams({})
    navigate('', { replace: true })
  }

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }

    if (jobs.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
            No jobs found
          </TableCell>
        </TableRow>
      )
    }

    return jobs.map((job: Job) => (
      <TableRow
        key={job.id}
        hover
        onClick={() => handleRowClick(job.id)}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell>{job.externalId || job.referenceNumber}</TableCell>
        <TableCell>{job.customer?.name}</TableCell>
        <TableCell>{job.title}</TableCell>
        <TableCell>{job.location}</TableCell>
        <TableCell>
          {job.externalCreatedAt ? new Date(job.externalCreatedAt).toLocaleDateString() : '-'}
        </TableCell>
        <TableCell>
          {job.importedAt ? new Date(job.importedAt).toLocaleDateString() : '-'}
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHeader headCells={headCells} />
            <TableBody>{renderTableContent()}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <JobDetailsModal job={selectedJob} open={!!selectedJobId} onClose={handleCloseModal} />
    </Box>
  )
}
