import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useSWR, { SWRResponse } from 'swr'
import { useDebounce } from 'use-debounce'
import axiosInstance from '../../../../../utils/axiosInstance'
import { useSelector } from '../../../../../store/store'

const useCampaignsList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  const { analyticsFilterQueryParams } = useSelector((state) => state.filters)

  const { data, isLoading, mutate }: SWRResponse = useSWR('/meta/campaigns', (url) => {
    const baseParams = {
      page,
      size: rowsPerPage,
      ...(debouncedSearchValue && { search: debouncedSearchValue }),
      createdAt: analyticsFilterQueryParams?.createdAt,
    }
    return axiosInstance.get(url, { params: baseParams })
  })

  useEffect(() => {
    mutate()
  }, [analyticsFilterQueryParams, mutate])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setSearchValue(params.get('search') || '')
  }, [location.search, mutate])

  useEffect(() => {
    navigate(debouncedSearchValue ? `?search=${debouncedSearchValue}` : '')
  }, [debouncedSearchValue, navigate])

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  }

  const handleChangePage = (_: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    mutate()
  }, [page, rowsPerPage, debouncedSearchValue, mutate])

  return {
    handleSearchChange,
    handleChangePage,
    handleChangeRowsPerPage,
    campaigns: data?.data?.campaigns || [],
    total: data?.data.total || 0,
    searchValue,
    rowsPerPage,
    isLoading,
    page,
  }
}

export default useCampaignsList
