import { useParams, useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import DialogBox from '../../../../components/Dialog'
import { leadPlatforms, leadStatuses } from '../../../../constants'
import FormErrorsModal from '../../../../ui-component/FormErrorsModal'
import SuccessModal from '../../../../ui-component/SuccessModal'
import axiosInstance from '../../../../utils/axiosInstance'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TresolvedAny } from '../../../../types'

type TLead = { name: string; values: string[] }[]

interface CreateLeadProps {
  open: boolean
  setOpen: (open: boolean) => void
}
function CreateLead({ open, setOpen }: CreateLeadProps) {
  const intl = useIntl()
  const [formdata, setFormData] = React.useState<TLead>([])

  const [searchParams] = useSearchParams()
  const { id: campaignId } = useParams()
  const id = searchParams.get('lead') || null
  const isUpdate = !!id

  const [formikErrors, setFormikErrors] = React.useState<string[]>([])
  const [errorsModalOpen, setErrorsModalOpen] = React.useState(false)
  const [successModalOpen, setSuccessModalOpen] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = React.useState('new')
  const [platform, setPlatform] = React.useState('ig')
  const [campaignData, setCampaignData] = React.useState({
    id: '',
    campaignTitle: '',
    questions: '',
    leads: [],
    originalCampaignId: '',
  })

  useEffect(() => {
    axiosInstance
      .get('/campaign', {
        params: { id: campaignId },
      })
      .then((res) => {
        setCampaignData(res.data)
        const { questions } = res.data
        const questionsArray = JSON.parse(questions).map((question) => {
          return {
            name: question.key,
            values: [''],
          }
        })
        setFormData(questionsArray)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }, [])

  interface IRenderQuestions {
    formdata: TLead
    setFormData: React.Dispatch<React.SetStateAction<TLead>>
  }

  const RenderQuestions = useCallback(
    ({ setFormData }: IRenderQuestions) => {
      const campaignQuestion = JSON.parse(campaignData.questions)

      return (
        <>
          {campaignQuestion?.map(({ label, key }) => (
            <Grid item xs={12} key={key} sx={{ marginTop: '10px' }}>
              <TextField
                label={label}
                name={key}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const { name, value } = e.target
                  setFormData((prev) => {
                    const updated = prev.map((item) => {
                      if (item.name === name) {
                        return { ...item, values: [value] }
                      }
                      return item
                    })
                    return updated
                  })
                }}
              />
            </Grid>
          ))}
        </>
      )
    },
    [campaignData.questions]
  )

  const handleSubmit = async () => {
    const data = {
      id: campaignData.id,
      fieldData: JSON.stringify(formdata),
      status,
      platform,
      campaignId,
      formId: campaignData.originalCampaignId,
      name: campaignData.campaignTitle,
    }

    try {
      const res = await axiosInstance.post('/lead', data)
      if (res.status === 200) {
        setSuccessModalOpen(true)
      }
    } catch (error: TresolvedAny) {
      setErrorsModalOpen(true)
      setFormikErrors([error.message])
    }
  }

  return (
    <DialogBox
      open={open}
      title="Create Lead"
      handleSubmit={handleSubmit}
      setOpen={(open) => {
        setOpen(!open)
      }}
      isLoading={false}
    >
      <Container>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="status"
            name="status"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            label={intl.formatMessage({ id: 'status' })}
            style={{ width: '20%' }}
          >
            {leadStatuses.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: '10px' }}>
          <InputLabel id="demo-simple-select-label">Platform</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="platform"
            name="platform"
            onChange={(e) => setPlatform(e.target.value)}
            value={platform}
            label={intl.formatMessage({ id: 'platform' })}
            style={{ width: '20%' }}
          >
            {leadPlatforms.map((platform) => (
              <MenuItem key={platform.value} value={platform.value}>
                {platform.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <RenderQuestions formdata={formdata} setFormData={setFormData} />
      </Container>

      <FormErrorsModal
        errorsModalOpen={errorsModalOpen}
        formikErrors={formikErrors}
        setErrorsModalOpen={setErrorsModalOpen}
      />
      <SuccessModal
        successModalOpen={successModalOpen}
        handleClose={() => setOpen(false)}
        setSuccessModalOpen={setSuccessModalOpen}
        message={isUpdate ? 'Edited successfully' : 'Created successfully'}
      />
    </DialogBox>
  )
}

export default CreateLead
