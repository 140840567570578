import { Container, Dialog, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import useSWR, { SWRResponse } from 'swr'
import axiosInstance from '../../../../../../../utils/axiosInstance'
import Loading from '../../../../../../../ui-component/loading/Loading'

interface Props {
  id: string
  open: boolean
  setOpen: (value: boolean) => void
}

export const ExternalAppUserDialog: React.FC<Props> = ({ id, open, setOpen }) => {
  const { data: connectedCustomer, isLoading }: SWRResponse = useSWR(
    `/app-users/${id}`,
    axiosInstance
  )
  const data = connectedCustomer?.data?.appUser

  let isConnectedCustomer = true
  if (data?.role === 'externalReseller') {
    !data.customer?.name && (isConnectedCustomer = false)
  }

  isLoading && <Loading />

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="ReadEditOrder"
        fullWidth
        maxWidth="md"
      >
        <Container maxWidth="md" className="formContainer">
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="user-details" />
            </div>

            <Grid spacing={2} container className="content">
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="app-user-name" />
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{`${data?.firstName} ${data?.lastName}`}</Typography>
              </Grid>
            </Grid>

            <Grid spacing={2} container className="content">
              {!isConnectedCustomer && (
                <Grid item xs={3}>
                  <Typography color="red" variant="h5">
                    <FormattedMessage id="no-connected-customer" />
                  </Typography>
                </Grid>
              )}
              {data?.role === 'externalReseller' && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="connected-customer" />
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">{data?.customer?.name}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Container>
      </Dialog>
    </div>
  )
}
