// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socialsStatsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}
.socialsStatsWrapper .social {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.socialsStatsWrapper .stats {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 50px;
}
.socialsStatsWrapper .stats span {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}
.socialsStatsWrapper .stats p {
  margin: 0;
  color: #616161;
  font-size: 12px;
  font-weight: 400;
}
.socialsStatsWrapper .icon {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #d3cfe4;
  padding: 6px;
  box-sizing: content-box;
  margin-right: 16px;
  color: #220d77;
}`, "",{"version":3,"sources":["webpack://./src/views/leads/socialsStats/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAAI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEN;AAAI;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAEN;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;AACJ","sourcesContent":[".socialsStatsWrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  height: 100%;\n  .social {\n    flex: 1 1 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 8px;\n  }\n\n  .stats {\n    display: flex;\n    flex-direction: column;\n    justify-items: center;\n    align-items: center;\n    width: 50px;\n    span {\n      color: #212121;\n      font-size: 14px;\n      font-weight: 500;\n    }\n    p {\n      margin: 0;\n      color: #616161;\n      font-size: 12px;\n      font-weight: 400;\n    }\n  }\n  .icon {\n    width: 24px;\n    height: 24px;\n    border-radius: 6px;\n    background: #d3cfe4;\n    padding: 6px;\n    box-sizing: content-box;\n    margin-right: 16px;\n    color: #220d77;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
