import { TableCell, TableHead, TableRow } from '@mui/material'

interface HeadCell {
  id: string
  label: string
}

interface EnhancedTableHeaderProps {
  headCells: HeadCell[]
}

export default function EnhancedTableHeader({ headCells }: EnhancedTableHeaderProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
