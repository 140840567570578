// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.boxWrapper h3 {
  font-size: 1.25rem;
  margin: 0;
}
.boxWrapper .boxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.boxWrapper .boxHeader .growth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.boxWrapper .boxHeader .growth p {
  font-size: 20px;
  font-weight: 700;
  color: #434243;
  margin: 0;
  margin-left: 10px;
}
.boxWrapper .boxHeader .growth .stockArrowUp {
  color: #00a43b;
}
.boxWrapper .boxHeader .growth .stockArrowDown {
  color: red;
}
.boxWrapper .boxHeader .total {
  font-size: 16px;
  color: #434243;
  font-weight: 700;
  margin: 0;
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/BoxWrapper/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,SAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;EACA,iBAAA;AAIR;AAFM;EACE,cAAA;AAIR;AAFM;EACE,UAAA;AAIR;AADI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,SAAA;EACA,eAAA;AAGN","sourcesContent":[".boxWrapper {\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  h3 {\n    font-size: 1.25rem;\n    margin: 0;\n  }\n  .boxHeader {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 32px;\n    .growth {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      align-items: center;\n      p {\n        font-size: 20px;\n        font-weight: 700;\n        color: #434243;\n        margin: 0;\n        margin-left: 10px;\n      }\n      .stockArrowUp {\n        color: #00a43b;\n      }\n      .stockArrowDown {\n        color: red;\n      }\n    }\n    .total {\n      font-size: 16px;\n      color: #434243;\n      font-weight: 700;\n      margin: 0;\n      margin-top: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
