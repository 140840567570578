// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
  IconBrandChrome,
  IconLayoutDashboard,
  IconListCheck,
  IconUsers,
  IconUserSearch,
  IconUser,
  IconReportAnalytics,
  IconForbid,
  IconBriefcase,
} from '@tabler/icons-react'
import { NavItemType } from '../types'
import { store } from '../store/store'
import { PAGE, USER_ROLES } from '../constants'

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const children: NavItemType[] = [
  {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'item',
    url: PAGE.DASHBOARD.PATH,
    icon: IconLayoutDashboard,
    breadcrumbs: false,
  },
  {
    id: 'orders',
    title: <FormattedMessage id="orders" />,
    type: 'item',
    url: PAGE.ORDERS.PATH,
    icon: IconListCheck,
    breadcrumbs: true,
  },
  {
    id: 'jobs',
    title: <FormattedMessage id="jobs" />,
    type: 'item',
    url: PAGE.JOBS.PATH,
    icon: IconBriefcase,
    breadcrumbs: true,
  },
  {
    id: 'customers',
    title: <FormattedMessage id="customers" />,
    type: 'item',
    url: PAGE.CUSTOMERS.PATH,
    icon: IconUser,
    breadcrumbs: true,
  },
  {
    id: 'leads',
    title: <FormattedMessage id="leads-talents" />,
    type: 'item',
    url: PAGE.LEADS.PATH,
    icon: IconUserSearch,
    breadcrumbs: true,
  },
  {
    id: 'accountsAndPermissions',
    title: <FormattedMessage id="accountsAndPermissions" />,
    type: 'collapse',
    url: PAGE.ACCOUNTS_AND_PERMISSIONS.ACCOUNTS.PATH,
    icon: IconUsers,
    breadcrumbs: true,
    children: [
      {
        id: 'accounts',
        title: (
          <div className="navSubChild">
            <FormattedMessage id="accounts" />
          </div>
        ),
        type: 'item',
        url: PAGE.ACCOUNTS_AND_PERMISSIONS.ACCOUNTS.PATH,
        icon: IconUser,
        breadcrumbs: true,
      },
      {
        id: 'permissions',
        title: (
          <div className="navSubChild">
            <FormattedMessage id="permissions" />
          </div>
        ),
        type: 'item',
        url: PAGE.ACCOUNTS_AND_PERMISSIONS.PERMISSIONS.PATH,
        icon: IconForbid,
        breadcrumbs: true,
      },
    ],
  },
  {
    id: 'analytics',
    title: <FormattedMessage id="analytics" />, // TODO: Display campaign name
    type: 'item',
    url: PAGE.ANALYTICS.PATH,
    icon: IconReportAnalytics,
    breadcrumbs: true,
  },
]
// subscribe to user role changes in store
store.subscribe(() => {
  // if (children[0]) return
  const { userInfo, permissions } = store.getState().user
  const { role } = userInfo

  role !== USER_ROLES.ADMIN.value && removeChild('accountsAndPermissions')
  role !== USER_ROLES.ADMIN.value && removeChild('jobs')
  role === USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value && removeChild('customers')

  if (permissions) {
    removeChildWithPermission(children, 'dashboard')
    removeChildWithPermission(children, 'orders')
    removeChildWithPermission(children, 'customers')
    removeChildWithPermission(children, 'leadsAndTalents', 'leads')
    role !== 'admin' &&
      role !== 'externalReseller' &&
      removeChildWithPermission(children, 'analytics')
  }

  function removeChildWithPermission(children, permission: string, id: string = permission) {
    if (!permissions?.[permission]) {
      const index = children.findIndex((item: { id: string }) => item.id === id)
      if (index > -1) {
        children.splice(index, 1)
      }
    }
  }
})

const other: NavItemType = {
  id: 'sample-docs-roadmap',
  icon: IconBrandChrome,
  type: 'group',
  children,
}

export default other

function removeChild(id: string) {
  const index = children.findIndex((item) => item.id === id)
  if (index > -1) {
    children.splice(index, 1)
  }
  return true
}
