import useSWR, { SWRResponse } from 'swr'
import './style.scss'
import { Dialog, Slide } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import BoxWrapper from '../../components/BoxWrapper'
import TableRender from '../../components/TableRender'
import { useDispatch, useSelector } from '../../store/store'
import { dialogToggle } from '../../store/slices/dialog'
import CreateCustomer from './create/CreateCustomer'
import axiosInstance from '../../utils/axiosInstance'
import { setTriggerCustomersRefetch } from '../../store/slices/general'

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Customers = () => {
  const [page, setPage] = useState(0)
  const defaultRowsPerPage = Number(localStorage.getItem('orderTableRowsPerPage')) || 5
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const take = page * rowsPerPage + rowsPerPage + 1

  const { customersFilterQueryParams, customerNameFilterQuerySearch: search } = useSelector(
    (state) => state.filters
  )

  const params = {
    take,
    search,
    ...customersFilterQueryParams,
  }
  // const { data: fetchedData, isLoading: loading, mutate } = useSWR(requestPath, axiosInstance)
  // const data = fetchedData?.data?.customers
  const {
    data: fetchedData,
    isLoading: loading,
    mutate,
  }: SWRResponse = useSWR('/customers', (url) => axiosInstance.get(url, { params }))
  const data = fetchedData?.data?.customers

  const { open } = useSelector((state) => state.dialog)

  const dispatch = useDispatch()
  const handleClose = () => dispatch(dialogToggle(false))

  const { triggerCustomersRefetch } = useSelector((state) => state.general)

  useEffect(() => {
    mutate()
  }, [customersFilterQueryParams, mutate, search])
  useEffect(() => {
    if (triggerCustomersRefetch) {
      mutate()
      dispatch(setTriggerCustomersRefetch(false))
    }
  }, [dispatch, mutate, triggerCustomersRefetch])

  return (
    <BoxWrapper height="100%">
      <TableRender
        data={data}
        defaultOrderBy={'name'}
        type={'customer'}
        toolbar
        pagination
        densePadding
        isLoading={loading}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageItemName="customerTableRowsPerPage"
      />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <CreateCustomer handleClose={handleClose} />
      </Dialog>
    </BoxWrapper>
  )
}

export default Customers
