import React, { useCallback, useState } from 'react'
import './style.scss'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import {
  AppBar,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import { IconCircleX } from '@tabler/icons-react'
import { useDropzone } from 'react-dropzone'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useSWR, { SWRResponse } from 'swr'
import { FormattedMessage, useIntl } from 'react-intl'
import queries from '../../../graphQL/query'
import SuccessModal from '../../../ui-component/SuccessModal'
import FormErrorsModal from '../../../ui-component/FormErrorsModal'
import { useDispatch, useSelector } from '../../../store/store'
import { setTriggerCustomersRefetch } from '../../../store/slices/general'
import FileUploadArea from '../../../ui-component/fileUpload/FileUploadArea'
import Loading from '../../../ui-component/loading/Loading'
import axiosInstance from '../../../utils/axiosInstance'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TresolvedAny } from '../../../types'
import { TcreatCustomerObject } from '../../../constants'
import AddNewItem from '../../../ui-component/addNewItem/AddNewItem'
import EditPhoneRow from '../EditPhoneRow'
import CreateContactRow from '../CreateContactRow'
import EditEmailRow from '../EditEmailRow'

interface Iprops {
  handleClose: () => void
}

interface Iselect {
  label: string
  value: string
}

export const customerTypes: Iselect[] = [
  { label: 'Reseller', value: 'partner' },
  { label: 'Reseller customer', value: 'resellerCustomer' },
  { label: 'Direct', value: 'direct' },
  { label: 'White lable', value: 'whiteLable' },
]

export type CustomerTypeValues = (typeof customerTypes)[number]['value']

export const customerValidationSchema = yup.object({
  name: yup.string().required('name is required').min(2, 'Too Short!').max(50, 'Too Long!'),
  url: yup.string().nullable().min(5, 'Too Short!').max(100, 'Too Long!'),
  newEmail: yup.string().email('Invalid email').nullable(),
  automaticTransactionalEmails: yup.boolean(),
  adAccountId: yup.string().nullable(),
  newPhone: yup.string().nullable(),
  address: yup.string(),
  type: yup
    .string()
    .required('Required')
    .oneOf(customerTypes.map((i) => i.value)),
  connectedReseller: yup.string().nullable(),
  connectedAppUser: yup.string().nullable(),
  logo: yup.object({ upload: yup.string() }).nullable(),
})

const CreateOrder: React.FC<Iprops> = ({ handleClose }) => {
  const intl = useIntl()
  const [customerType, setCustomerType] = React.useState<CustomerTypeValues>('partner')
  const [logoFile, setlogoFile] = React.useState<null | File>(null)

  const [formikErrors, setFormikErrors] = React.useState<string[]>([])
  const [errorsModalOpen, setErrorsModalOpen] = React.useState(false)
  const [successModalOpen, setSuccessModalOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [addNewEmailClicked, setAddNewEmailClicked] = useState(false)
  const [addNewPhoneClicked, setAddNewPhoneClicked] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [newPhone, setNewPhone] = useState('')
  const [phones, setPhones] = useState<string[]>([])
  const [emails, setEmails] = useState<string[]>([])
  const [defaultInvoiceEmail, setDefaultInvoiceEmail] = useState('')
  const [defaultPreviewEmail, setDefaultPreviewEmail] = useState('')
  const [defaultEmail, setDefaultEmail] = useState('')

  const handleNewEmailApprove = useCallback(() => {
    setEmails([...emails, newEmail])
  }, [emails, newEmail])
  const handleNewPhoneApprove = useCallback(() => {
    setPhones([...phones, newPhone])
  }, [phones, newPhone])

  const dispatch = useDispatch()

  const { data: fetchedCustomers } = useQuery(queries.customers)
  const { data: fetchedAppUsers }: SWRResponse = useSWR('/app-users', axiosInstance)
  const appUsers = fetchedAppUsers?.data?.appUsers

  const onDropLogoFile = useCallback((acceptedFiles: [null] | File[]) => {
    if (acceptedFiles?.length) setlogoFile(acceptedFiles[0])
  }, [])

  const {
    acceptedFiles: acceptedFilesLogo,
    getRootProps: getRootPropsLogo,
    getInputProps: getInputPropsLogo,
    isDragActive: isDragActiveLogo,
  } = useDropzone({
    onDrop: onDropLogoFile,
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      url: '',
      automaticTransactionalEmails: true,
      adAccountId: '',
      address: '',
      type: customerTypes[0].value,
      connectedReseller: '',
      appUser: '',
      logo: '',
    },
    validationSchema: customerValidationSchema,
    onSubmit: async (values) => {
      const newFormData: TcreatCustomerObject = {
        name: values.name,
        url: values.url,
        phoneNumbers: JSON.stringify(phones),
        emails: JSON.stringify(emails),
        automaticTransactionalEmails: values.automaticTransactionalEmails,
        adAccountId: values.adAccountId,
        defaultEmail,
        defaultInvoiceEmail,
        defaultPreviewEmail,
        address: values.address,
        type: values.type,
        connectedReseller: null,
        appUser: null,
        logo: null,
      }

      if (logoFile) {
        newFormData.logo = {
          upload: logoFile,
        }
      }
      if (values.connectedReseller) {
        newFormData.connectedReseller = { connect: { id: values.connectedReseller } }
      }
      if (values.appUser) {
        newFormData.appUser = { connect: { id: values.appUser } }
      }

      const graphqlData = {
        variables: { data: newFormData },
      }

      const formData = new FormData()
      formData.append('graphqlData', JSON.stringify(graphqlData))
      if (logoFile) formData.append('logo', logoFile)

      try {
        const res = await axiosInstance.post('/createCustomer', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (res.data?.createCustomer?.name) {
          setSuccessModalOpen(true)
          dispatch(setTriggerCustomersRefetch(true))
        }
      } catch (error: TresolvedAny) {
        setFormikErrors([error.response.data.message])
        setErrorsModalOpen(true)
      }
      setIsLoading(false)
    },
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const errors = Object.values(formik.errors)
    if (errors.length) {
      setErrorsModalOpen(true)
      setFormikErrors(errors as string[])
      return
    }
    if (logoFile) await formik.setFieldValue('logo', { upload: logoFile })

    setIsLoading(true)
    formik.handleSubmit()
  }

  const { isUserRoleInternal } = useSelector((state) => state.user.userInfo)

  return (
    <div className="CreateCustomer">
      <form onSubmit={(e) => handleSubmit(e)}>
        <AppBar sx={{ position: 'sticky' }} color="default">
          <Toolbar>
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <IconCircleX size="50px" color="gray" />
            </IconButton>
            <Typography sx={{ ml: 3, flex: 1, textAlign: 'center' }} variant="h3" component="div">
              <FormattedMessage id="create-new-customer" />
            </Typography>
            <Button variant="contained" color="secondary" autoFocus size="large" type="submit">
              <FormattedMessage id="create" />
            </Button>
          </Toolbar>
        </AppBar>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="formContainer">
            <Container maxWidth="md" className="mainData subFormContainer">
              <div className="header">
                <FormattedMessage id="customer" />
              </div>
              <Grid container className="content">
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  placeholder="Customer name"
                  type="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />
                <FormControl fullWidth>
                  <InputLabel id="type">
                    <FormattedMessage id="customer-type" />
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="type"
                    id="type"
                    name="type"
                    value={formik.values.type}
                    label="customerType"
                    onChange={(e) => {
                      formik.handleChange(e)
                      setCustomerType(e.target.value)
                    }}
                  >
                    {customerTypes.map((i: Iselect) => (
                      <MenuItem key={i.value} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {customerType === 'resellerCustomer' && (
                  <FormControl fullWidth>
                    <InputLabel id="connectedReseller">
                      <FormattedMessage id="connected-reseller" />
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="connectedReseller"
                      id="connectedReseller"
                      name="connectedReseller"
                      value={formik.values.connectedReseller}
                      label={intl.formatMessage({ id: 'connected-reseller' })}
                      onChange={formik.handleChange}
                    >
                      {fetchedCustomers.customers.map((i: { name: string; id: string }) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {isUserRoleInternal && (
                  // <FormControl fullWidth>
                  //   <InputLabel id="connectedAppUser">
                  //     <FormattedMessage id="connected-app-user" />
                  //   </InputLabel>
                  //   <Select
                  //     fullWidth
                  //     labelId="appUser"
                  //     id="appUser"
                  //     name="appUser"
                  //     value={formik.values.appUser}
                  //     label={intl.formatMessage({ id: 'connected-app-user' })}
                  //     onChange={formik.handleChange}
                  //   >
                  //     {appUsers?.map((i: { firstName: string; email: string; id: string }) => (
                  //       <MenuItem key={i.id} value={i.id}>
                  //         {`${i.firstName} - ${i.email}`}
                  //       </MenuItem>
                  //     ))}
                  //   </Select>
                  // </FormControl>
                  //   <Autocomplete
                  //   fullWidth
                  //   options={campaigns}
                  //   getOptionLabel={(option: IdAndName) => option.name}
                  //   onChange={(e, value) => {
                  //     formik.setFieldValue('metaCampaign', value?.id)
                  //   }}
                  //   renderInput={(params) => (
                  //     <TextField
                  //       {...params}
                  //       label={<FormattedMessage id="metaCampaign" />}
                  //       id="metaCampaign"
                  //       name="metaCampaign"
                  //       placeholder="Meta Campaign"
                  //       type="text"
                  //       value={formik.values.metaCampaign}
                  //       onChange={formik.handleChange}
                  //       error={formik.touched.metaCampaign && Boolean(formik.errors.metaCampaign)}
                  //     />
                  //   )}
                  // />
                  <Autocomplete
                    fullWidth
                    options={appUsers}
                    getOptionLabel={(option: { firstName: string; email: string; id: string }) =>
                      `${option.firstName} ${option.firstName && '-'} ${option.email}`
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue('appUser', value?.id)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<FormattedMessage id="appUser" />}
                        id="appUser"
                        name="appUser"
                        placeholder="App User"
                        type="text"
                        value={formik.values.appUser}
                        onChange={formik.handleChange}
                        error={formik.touched.appUser && Boolean(formik.errors.appUser)}
                      />
                    )}
                  />
                )}
              </Grid>
            </Container>
            <Container maxWidth="md" className="customerDetails subFormContainer">
              <div className="header">
                <FormattedMessage id="contact-details" />
              </div>
              <Grid container className="content">
                <TextField
                  fullWidth
                  id="url"
                  name="url"
                  placeholder="Website"
                  type="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                />

                <TextField
                  fullWidth
                  id="address"
                  name="address"
                  placeholder="Address"
                  type="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                />
              </Grid>

              <div className="header">
                <FormattedMessage id="email" />
              </div>
              <Grid container className="content">
                {emails.length ? (
                  emails.map((email) => (
                    <EditEmailRow
                      emails={emails}
                      email={email}
                      setEmails={setEmails}
                      key={email}
                      defaultInvoiceEmail={defaultInvoiceEmail}
                      setDefaultInvoiceEmail={setDefaultInvoiceEmail}
                      defaultPreviewEmail={defaultPreviewEmail}
                      setDefaultPreviewEmail={setDefaultPreviewEmail}
                      defaultEmail={defaultEmail}
                      setDefaultEmail={setDefaultEmail}
                    />
                  ))
                ) : (
                  <div />
                )}
                <div className="newContactCont">
                  {addNewEmailClicked && (
                    <div>
                      <div className="newEmail">
                        <CreateContactRow
                          type="email"
                          setContact={setNewEmail}
                          setItemClicked={() => setAddNewEmailClicked(false)}
                          handleApprove={() => handleNewEmailApprove()}
                        />
                      </div>
                    </div>
                  )}
                  <AddNewItem item="E-mail" handleAddItem={() => setAddNewEmailClicked(true)} />
                </div>
              </Grid>
              <div className="header">
                <FormattedMessage id="phone" />
              </div>
              <Grid container className="content">
                {phones.length ? (
                  phones.map((phone) => (
                    <EditPhoneRow key={phone} phone={phone} setPhones={setPhones} />
                  ))
                ) : (
                  <div />
                )}
                <div className="newContactCont">
                  {addNewPhoneClicked && (
                    <div>
                      <div className="newEmail">
                        <CreateContactRow
                          type="phone"
                          setContact={setNewPhone}
                          setItemClicked={() => setAddNewPhoneClicked(false)}
                          handleApprove={() => handleNewPhoneApprove()}
                        />
                      </div>
                    </div>
                  )}
                  <AddNewItem
                    item={intl.formatMessage({ id: 'phone-number' })}
                    handleAddItem={() => setAddNewPhoneClicked(true)}
                  />
                </div>
              </Grid>

              {/* automaticTransactionalEmails */}
              <div className="header">
                <FormattedMessage id="auto-transactional-emails" />
              </div>
              <Grid container className="content">
                <FormGroup className="checkboxes">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.automaticTransactionalEmails}
                        onChange={formik.handleChange}
                        name="automaticTransactionalEmails"
                      />
                    }
                    label={intl.formatMessage({ id: 'auto-transactional-emails' })}
                  />
                </FormGroup>
              </Grid>
            </Container>
            <Container maxWidth="md" className="logo subFormContainer">
              <div className="header">Logo</div>
              <div className="content">
                <FileUploadArea
                  title="Logo"
                  getInputProps={getInputPropsLogo}
                  getRootProps={getRootPropsLogo}
                  acceptedFiles={acceptedFilesLogo}
                  isDragActive={isDragActiveLogo}
                  withResults
                />
              </div>
            </Container>
          </div>
        )}

        <FormErrorsModal
          errorsModalOpen={errorsModalOpen}
          formikErrors={formikErrors}
          setErrorsModalOpen={setErrorsModalOpen}
        />
        <SuccessModal
          successModalOpen={successModalOpen}
          handleClose={handleClose}
          setSuccessModalOpen={setSuccessModalOpen}
          message={intl.formatMessage({ id: 'created-successfully' })}
        />
      </form>
    </div>
  )
}

export default CreateOrder
