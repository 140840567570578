import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import useSWR, { SWRResponse } from 'swr'
import dayjs, { Dayjs } from 'dayjs'
import BoxWrapper from '../../components/BoxWrapper'
import LeadsPerCampaign from './leadsPerCampaign'
import LeadsPerMonth from './leadsPerMonth'
import SocialStats from './socialsStats'
// import LeadsTable from './leadsTable'
import TableRender from '../../components/TableRender'
import axiosInstance from '../../utils/axiosInstance'
import { useDispatch, useSelector } from '../../store/store'

type TStatus = 'ACTIVE' | 'ARCHIVED' | 'DELETED' | 'DRAFT'
export interface Campaign {
  customer: string
  pageName: string
  campaign: string
  createdTime: Dayjs | string
  totalLeads: number
  id: string
  questions: string[]
  status: TStatus
}

function createData(
  customer: string,
  pageName: string,
  campaign: string,
  createdTime: Dayjs | string,
  totalLeads: number,
  id: string,
  questions: string[],
  status: TStatus
): Campaign {
  return {
    customer,
    pageName,
    campaign,
    createdTime,
    totalLeads,
    id,
    questions,
    status,
  }
}

function Campaigns() {
  const [rows, setRows] = useState<Campaign[]>([])
  const [leads, setLeads] = useState([])

  const { campaignsFilterQueryParams, campaignNameFilterQuerySearch: search } = useSelector(
    (state) => state.filters
  )
  const params = {
    search,
    ...campaignsFilterQueryParams,
    mergedTo: '',
  }

  const { data, isLoading, mutate }: SWRResponse = useSWR('/campaigns', (url) =>
    axiosInstance.get(url, { params })
  )
  const dispatch = useDispatch()

  const { permissions } = useSelector((state) => state.user)

  // eslint-disable-next-line no-unsafe-optional-chaining
  const averageLeads = Math.round(leads?.length / rows?.length) || 0

  useEffect(() => {
    axiosInstance.get('/leads').then((res) => {
      setLeads(res.data)
    })
  }, [])

  useEffect(() => {
    if (data) {
      const rowsData = data?.data
        ?.filter((item) => Boolean(item))
        .map((item) => {
          const { createdTime, leads, name, page: pageName, id, questions, task, status } = item

          const companyName = task?.customer?.name
          return createData(
            companyName,
            pageName,
            name,
            dayjs(createdTime),
            leads.length,
            id,
            questions,
            status
          )
        })

      setRows(rowsData)
    }
  }, [data, dispatch, leads])

  useEffect(() => {
    mutate()
  }, [campaignsFilterQueryParams, search, mutate])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <BoxWrapper header title={<FormattedMessage id="leads-per-month" />} height="100%">
            <LeadsPerMonth leads={leads} />
          </BoxWrapper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BoxWrapper height="100%">
            <SocialStats />
          </BoxWrapper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BoxWrapper
            header
            title={<FormattedMessage id="leads-per-campaign" />}
            flexDirection="column"
            value={rows ? Math.round(averageLeads) : 0}
            height="100%"
          >
            <LeadsPerCampaign leads={leads} campaignRows={rows} />
          </BoxWrapper>
        </Grid>
      </Grid>
      {permissions?.campaigns && permissions.campaignsTable && (
        <Grid item xs={12} container spacing={2} sx={{ height: '100%', marginTop: '0px' }}>
          <Grid item xs={12} md={12} lg={12}>
            <BoxWrapper header title="Campaigns" height="100%">
              <TableRender
                data={rows}
                defaultOrderBy="createdTime"
                type={'campaign'}
                toolbar
                pagination
                densePadding
                isLoading={isLoading}
              />
            </BoxWrapper>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Campaigns
