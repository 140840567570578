import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  TextField,
  Autocomplete,
  CircularProgress,
  Link,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Job } from '../../../../types/job'
import axiosInstance from '../../../../utils/axiosInstance'
import { useToast } from '../../../../contexts/ToastContext'

interface MetaCampaign {
  id: string
  name: string
  createdTime: string
}

interface JobDetailsModalProps {
  job: Job | null
  open: boolean
  onClose: () => void
}

export default function JobDetailsModal({ job, open, onClose }: JobDetailsModalProps) {
  const [metaCampaigns, setMetaCampaigns] = useState<MetaCampaign[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedMetaCampaign, setSelectedMetaCampaign] = useState<MetaCampaign | null>(null)
  const [saving, setSaving] = useState(false)
  const { showToast } = useToast()

  useEffect(() => {
    if (open && job) {
      setLoading(true)
      // Fetch meta campaigns (leadgenforms)
      axiosInstance
        .get('/meta-campaigns')
        .then((response) => {
          setMetaCampaigns(response.data || [])

          // If job has a metaCampaignId, find and select that campaign
          if (job.metaCampaignId) {
            const campaign = response.data.find((c: MetaCampaign) => c.id === job.metaCampaignId)
            if (campaign) {
              setSelectedMetaCampaign(campaign)
            }
          } else {
            setSelectedMetaCampaign(null)
          }
        })
        .catch((error) => {
          console.error('Error fetching meta campaigns:', error)
          showToast('Failed to load leadgenforms', 'error')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [open, job, showToast])

  const handleSave = async () => {
    if (!job) return

    setSaving(true)
    try {
      // Update the job with the selected metaCampaign
      await axiosInstance.put(`/jobs/${job.id}`, {
        metaCampaignId: selectedMetaCampaign?.id || null,
      })

      showToast('Job updated successfully', 'success')
      onClose()
    } catch (error) {
      console.error('Error updating job:', error)
      showToast('Failed to update job', 'error')
    } finally {
      setSaving(false)
    }
  }

  if (!job) return null

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">{job.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            External ID
          </Typography>
          <Typography variant="body1" paragraph>
            {job.externalId || job.referenceNumber}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Customer
          </Typography>
          <Typography variant="body1" paragraph>
            {job.customer?.name || '-'}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Location
          </Typography>
          <Typography variant="body1" paragraph>
            {job.location}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Description
          </Typography>
          <Typography variant="body1" paragraph>
            {job.description || '-'}
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="subtitle1" gutterBottom>
            Connected Leadgenform
          </Typography>
          {job.metaCampaignId && selectedMetaCampaign ? (
            <Box sx={{ mb: 2 }}>
              <Link
                component={RouterLink}
                to={`/campaigns/${selectedMetaCampaign.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body1" color="primary">
                  {selectedMetaCampaign.name}
                </Typography>
              </Link>
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              No leadgenform connected
            </Typography>
          )}

          <Typography variant="subtitle1" gutterBottom>
            Connect to Leadgenform
          </Typography>
          <Autocomplete
            id="leadgenform-select"
            options={metaCampaigns}
            getOptionLabel={(option) => option.name}
            value={selectedMetaCampaign}
            onChange={(_, newValue) => {
              setSelectedMetaCampaign(newValue)
            }}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Leadgenform"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            sx={{ mb: 2 }}
          />

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box>
              <Typography variant="subtitle2" color="text.secondary">
                Created at
              </Typography>
              <Typography variant="body2">
                {job.externalCreatedAt ? new Date(job.externalCreatedAt).toLocaleDateString() : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="text.secondary">
                Imported at
              </Typography>
              <Typography variant="body2">
                {job.importedAt ? new Date(job.importedAt).toLocaleDateString() : '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={saving}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
