import { forwardRef, useState } from 'react'
import useSWR, { SWRResponse } from 'swr'
import dayjs from 'dayjs'
import { Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import EuroIcon from '@mui/icons-material/Euro'
import AddIcon from '@mui/icons-material/Add'
import PeopleIcon from '@mui/icons-material/People'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  Dialog,
  Typography,
  Slide,
  Link,
} from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { TransitionProps } from '@mui/material/transitions'
import { LineChart, XAxis, Line } from 'recharts'
import { FormattedMessage } from 'react-intl'
import { InfoCard } from '../../ui-component'
import BoxWrapper from '../../components/BoxWrapper'
import icon from '../../assets/images/card-bg.svg'
import axiosInstance from '../../utils/axiosInstance'
import CreateOrderDenied from '../orders/create/CreateOrderDenied'
import PlatformIcon from '../../ui-component/PlatformIcon'
import { Iorder, MetaLeads } from '../../types/task'
import Loading from '../../ui-component/loading/Loading'
import { useSelector } from '../../store/store'

const dummyData = [
  { name: 'Jan', Line1: 12, Line2: 23 },
  { name: 'Feb', Line1: 13, Line2: 34 },
  { name: 'Mar', Line1: 45, Line2: 45 },
  { name: 'Apr', Line1: 23, Line2: 65 },
  { name: 'May', Line1: 7, Line2: 43 },
  { name: 'Jun', Line1: 12, Line2: 32 },
  { name: 'Jul', Line1: 4, Line2: 25 },
  { name: 'Aug', Line1: 67, Line2: 36 },
  { name: 'Sep', Line1: 43, Line2: 47 },
  { name: 'Oct', Line1: 32, Line2: 53 },
  { name: 'Nov', Line1: 12, Line2: 23 },
  { name: 'Dec', Line1: 36, Line2: 1 },
]

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const sx = {
  backgroundImage: `url(${icon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right',
}

const CustomerDashboard = () => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const { role } = useSelector((state) => state?.user.userInfo)
  const isDirectCustomer = role === 'externalDirectCustomer'
  const isPartnerCustomer = role === 'externalPartnerCustomer'

  const { data: ordersData, isLoading }: SWRResponse = useSWR(
    '/orders?take=5&orderBy=createdAt&orderDirection=desc&include=metaCampaign',
    axiosInstance
  )
  const orders = ordersData?.data?.orders

  const { data: leads }: SWRResponse = useSWR(
    '/leads?take=5&orderBy=createdTime&orderDirection=desc',
    axiosInstance
  )

  const { data: customerDashBoard, isLoading: customerDashBoardLoading }: SWRResponse = useSWR(
    '/customerDashboard',
    axiosInstance
  )
  if (isLoading) return <Loading />

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <BoxWrapper sx={sx}>
          {customerDashBoardLoading ? (
            <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
          ) : (
            <InfoCard
              Icon={NoteAltIcon}
              size={'s'}
              subtitle={'Active ads'}
              value={customerDashBoard?.data?.activeOrdersCount || 0}
            />
          )}
        </BoxWrapper>
      </Grid>
      <Grid item sm={4} xs={12}>
        <BoxWrapper sx={sx}>
          {customerDashBoardLoading ? (
            <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
          ) : (
            <InfoCard
              Icon={PeopleIcon}
              size={'s'}
              subtitle={'Leads'}
              value={customerDashBoard?.data?.leadsCount || 0}
            />
          )}
        </BoxWrapper>
      </Grid>
      {!isPartnerCustomer && (
        <Grid item sm={4} xs={12}>
          <BoxWrapper sx={sx}>
            {customerDashBoardLoading ? (
              <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            ) : (
              <InfoCard
                Icon={EuroIcon}
                size={'s'}
                subtitle={'Cost per lead'}
                value={customerDashBoard?.data?.costPerLead || 0}
              />
            )}
          </BoxWrapper>
        </Grid>
      )}
      {!!orders?.length && (
        <Grid item md={8} xs={12}>
          <Paper>
            <Box padding="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h3">Your active ads</Typography>
              <Button variant="outlined" onClick={handleOpen}>
                <Box display="flex" gap="6px" alignItems="center">
                  <AddIcon />
                  <FormattedMessage id="new-order" />
                </Box>
              </Button>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {!isDirectCustomer && (
                      <TableCell>
                        <FormattedMessage id="customer" />
                      </TableCell>
                    )}
                    <TableCell>
                      <FormattedMessage id="order-title" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="location" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="portal" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="leads" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders?.map((order: Iorder) => (
                    <TableRow key={`order-${order?.id}`}>
                      {!isDirectCustomer && <TableCell>{order?.customer?.name}</TableCell>}
                      <TableCell>{order?.jobName}</TableCell>
                      <TableCell>{order?.place}</TableCell>
                      <TableCell>
                        <Box display="flex" gap="4px">
                          {order?.channel?.map((channel) => (
                            <PlatformIcon
                              key={`order-${order?.id}-${channel}`}
                              platform={channel}
                            />
                          ))}
                        </Box>
                      </TableCell>
                      <TableCell align="right">{order?.metaCampaign?.leads?.length || 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box padding="16px" display="flex" alignItems="center" justifyContent="center">
              <Link
                display="flex"
                alignItems="center"
                underline="none"
                component={RouterLink}
                to="/orders"
              >
                <FormattedMessage id="view-All" />
                <KeyboardArrowRightIcon fontSize="small" />
              </Link>
            </Box>
          </Paper>
        </Grid>
      )}
      {!!leads?.data?.length && (
        <Grid item md={4} xs={12}>
          <Paper>
            <Box padding="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h3">
                <FormattedMessage id="your-leads" />
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="name" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="portal" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="date" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leads?.data?.map((lead: MetaLeads) => {
                    const leadField = lead?.fieldData ? JSON.parse(lead.fieldData) : []
                    const name = leadField.find((field) => field.name === 'full_name')

                    return (
                      <TableRow key={`order-${lead?.id}`}>
                        <TableCell>{name?.values[0]}</TableCell>
                        <TableCell>
                          <PlatformIcon platform={lead?.platform} />
                        </TableCell>
                        <TableCell align="right">
                          {dayjs(lead?.createdTime).format('DD.MM.YYYY')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box padding="16px" display="flex" alignItems="center" justifyContent="center">
              <Link
                display="flex"
                alignItems="center"
                underline="none"
                component={RouterLink}
                to="/leads"
              >
                <FormattedMessage id="view-All" />
                <KeyboardArrowRightIcon fontSize="small" />
              </Link>
            </Box>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box position="relative">
          <Box
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: '#fff', opacity: 0.8, zIndex: 10 }}
          >
            <Typography variant="h3">
              <FormattedMessage id="coming-soon" />
            </Typography>
          </Box>
          <Paper>
            <Box padding="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h3">
                <FormattedMessage id="analytics" />
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" p="16px" overflow="hidden">
              <LineChart width={1136} height={387} data={dummyData}>
                <XAxis dataKey="name" />
                <Line type="monotone" dataKey="Line1" stroke="#2196F3" />
                <Line type="monotone" dataKey="Line2" stroke="#220D77" />
              </LineChart>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <CreateOrderDenied />
      </Dialog>
    </Grid>
  )
}

export default CustomerDashboard
