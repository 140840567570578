import { Toolbar, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

export default function EnhancedTableToolbar() {
  const intl = useIntl()

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        {intl.formatMessage({ id: 'jobs' })}
      </Typography>
    </Toolbar>
  )
}
