import { useEffect, useState } from 'react'
import useSWR, { SWRResponse } from 'swr'
import UserTableRender from './table'
import axiosInstance from '../../../../utils/axiosInstance'
import { TableData } from '../../../../constants'
import { useDispatch, useSelector } from '../../../../store/store'
import { setTriggerAppUsersRefetch } from '../../../../store/slices/general'

const ExternalUsers = () => {
  const [page, setPage] = useState(0)
  const defaultRowsPerPage = Number(localStorage.getItem('orderTableRowsPerPage')) || 5
  const [, setRowsPerPage] = useState(defaultRowsPerPage)

  const { externalUsersFilterQueryParams, externalUsersFilterQuerySearch: search } = useSelector(
    (state) => state.filters
  )

  const params = {
    internalUsers: false,
    name: search,
  }
  externalUsersFilterQueryParams && Object.assign(params, externalUsersFilterQueryParams)

  const {
    data: fetchedData,
    isLoading: loading,
    mutate,
  }: SWRResponse = useSWR('/app-users', (url) => axiosInstance.get(url, { params }))
  const data = fetchedData?.data?.appUsers

  const dispatch = useDispatch()
  const { triggerAppUsersRefetch } = useSelector((state) => state.general)

  useEffect(() => {
    mutate()
  }, [externalUsersFilterQueryParams, mutate, search])

  useEffect(() => {
    if (triggerAppUsersRefetch) {
      mutate()
      dispatch(setTriggerAppUsersRefetch(false))
    }
  }, [dispatch, mutate, triggerAppUsersRefetch])

  let rowsData: TableData[] | [] = []
  if (data) {
    rowsData = data?.map((row) => {
      const { id, firstName, lastName, role, email, isAuthenticated } = row
      return {
        id,
        name: `${firstName} ${lastName}`,
        role,
        email,
        isAuthenticated,
      }
    })
  }

  return (
    <div>
      <UserTableRender
        data={rowsData}
        defaultOrderBy={'name'}
        toolbar
        pagination
        densePadding
        isLoading={loading}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageItemName="customerTableRowsPerPage"
      />
    </div>
  )
}

export default ExternalUsers
