// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiImageEdit .uploadArea {
  margin-top: 16px;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/ui-component/multiImageEdit/style.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,mBAAA;AAAR","sourcesContent":[".MultiImageEdit {\n    .uploadArea {\n        margin-top: 16px;\n        margin-bottom: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
