import { HeadCellCustomersTable } from '../types/other'

export * from './layout'
export * from './page'
export * from './user'

interface HeadCell {
  disablePadding?: boolean
  id: keyof TableData
  label: string
  numeric: boolean
}

export interface IsearchBy {
  where: {
    createdAt?: { gte: string; lt: string }
    assignee?: { id: { equals: string } }
    jobType?: { equals: string }
  }
}

export interface TableData {
  id: string
  customerID: string
  customerName: string
  logo?: string
  logoUrl: string
  customerType: string
  connectedReseller?: string
  jobType: string
  jobName: string
  status: string
  place: string
  createdAt: string
  assignee: string
  channel: string
  budget: string
  runtime: string
  price: string
  advanced?: string
  customerEmails: string
  previews?: Record<string, string>[]
}

export type TqueryRow = {
  id: string
  customerName: string
  logo: string
  customerType: string
  jobType: string
  jobName: string
  status: string
  place: string
  createdAt: string
  assignee: string
  channel: string
  budget: string
  runtime: string
  advanced: string
}

export type Torder = {
  id: string
  customer: {
    id: string
    name: string
    type: string
    logo: { url: string }
    logoUrl: string
    connectedReseller: { name: string }
    emails: string
  }
  logo: string
  customerType: string
  connectedReseller?: string
  jobType: string
  jobName: string
  status: string
  place: string
  createdAt: string
  assignee: { firstName: string; lastName: string }
  channel: [string]
  budget: string
  runtime: string
  price: string
  previews: Record<string, string>[]
}

export interface Iassignee {
  id: string
  firstName: string
  lastName: string
}

export interface Iselect {
  label: string
  value: string
}

export const sampleCustomerTableRow = {
  id: '',
  name: '',
  logo: '',
  type: '',
  address: '',
  email: '',
  phoneNumber: '',
}

export const sampleTasksTableRow = {
  id: '',
  customerName: '',
  logo: '',
  customerType: '',
  jobType: '',
  jobName: '',
  status: '',
  place: '',
  createdAt: '',
  assignee: '',
  channel: '',
  budget: '',
  runtime: '',
  advanced: '...',
}

export const filterTypes = { datePicker: 'datePicker', autoComplete: 'autocomplete' }

export const sortOptions = [
  { label: 'Customer Name', value: 'customerName' },
  { label: 'Order name', value: 'jobName' },
  { label: 'Status', value: 'status' },
  { label: 'Place', value: 'place' },
  { label: 'Date created', value: 'createdAt' },
  { label: 'Assignee', value: 'assignee' },
  { label: 'Portal', value: 'channel' },
  { label: 'Budget', value: 'budget' },
  { label: 'Runtime', value: 'runtime' },
]

export const sortOptionsLeads = [
  { label: 'Customer', value: 'customer' },
  { label: 'Reseller', value: 'reseller' },
  { label: 'Campaign', value: 'campaign' },
  { label: 'Date', value: 'date' },
  { label: 'Total leads', value: 'totalLeads' },
]

export const searchInOptions = [
  { label: 'Order name', value: 'task name' },
  { label: 'Customer', value: 'customer' },
  { label: 'Place', value: 'place' },
]

export const searchInOptionsLeads = [
  { label: 'Customer', value: 'customer' },
  { label: 'Reseller', value: 'reseller' },
  { label: 'Campaign', value: 'campaign' },
]

export const headCells: readonly HeadCell[] = [
  {
    id: 'customerName',
    numeric: false,
    label: 'Customer',
  },
  {
    id: 'jobName',
    numeric: false,
    label: 'Order',
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
  },
  {
    id: 'place',
    numeric: false,
    label: 'Place',
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Date created',
  },
  {
    id: 'assignee',
    numeric: false,
    label: 'Assignee',
  },
  {
    id: 'channel',
    numeric: false,
    label: 'Portal',
  },
  {
    id: 'budget',
    numeric: false,
    label: 'Budget',
  },
  {
    id: 'runtime',
    numeric: false,
    label: 'Runtime',
  },
  {
    id: 'advanced',
    numeric: false,
    label: '',
  },
]

export const historyHeadCells = [
  {
    id: 'date',
    label: 'date',
  },
  {
    id: 'user',
    label: 'user',
  },
  {
    id: 'event',
    label: 'event',
  },
  {
    id: 'action',
    label: 'action',
  },
]

export const orderHistoryStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
}

export const headCellsCustomersTable: readonly HeadCellCustomersTable[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone number',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
  },
]

export const genders = ['(m/w/d)']

export const platforms = ['ig', 'fb']

export const leadPlatforms = [
  { label: 'Facebook', value: 'fb' },
  { label: 'Instagram', value: 'ig' },
  { label: 'Typebot', value: 'typebot' },
]

export const allowedImageFileTypes = {
  image: {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    GIF: 'image/gif',
  },
}

export const allowedPreviewFileTypes = {
  file: {
    JPEG: 'image/jpeg',
    JPG: 'image/jpg',
    PNG: 'image/png',
    // TODO: add scg support
    // SVG: 'image/svg+xml',
    MP3: 'audio/mpeg',
    MP4: 'video/mp4',
  },
}

export const allowedVideoFileTypes = {
  video: {
    MP4: 'video/mp4',
    M3U8: 'application/x-mpegURL',
    MP2T: 'video/MP2T',
    '3GPP': 'video/3gpp',
    QT: 'video/quicktime',
    avi: 'video/x-msvideo',
    WMV: 'video/x-ms-wmv',
    MKV: 'video/x-matroska',
    MOV: 'video/quicktime',
    WEBM: 'video/webm',
    OGG: 'video/ogg',
    M4V: 'video/x-m4v',
  },
}

export const allowedDocumentFileTypes = {
  document: {
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
}

export const leadStatuses = [
  { label: 'New', value: 'new' },
  {
    label: 'In Progress',
    value: 'inProgress',
  },
  {
    label: 'Contacted',
    value: 'contacted',
  },
  {
    label: 'Hired',
    value: 'hired',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  { label: 'Spam', value: 'spam' },
  {
    label: 'Synced',
    value: 'synced',
  },
]

export type TcreatCustomerObject = {
  name: string
  url: string
  emails: string
  phoneNumbers: string
  defaultEmail: string
  defaultInvoiceEmail: string
  defaultPreviewEmail: string
  address: string
  type: string
  automaticTransactionalEmails: boolean
  adAccountId: string
  connectedReseller: null | { connect: { id: string } } | { disconnect: true }
  appUser: null | { connect: { id: string } } | { disconnect: true }
  logo: {
    upload: File
  } | null
  metaCampaign?: null | { connect: { id: string } } | { disconnect: true }
}

export const channels = [
  { label: 'Meta', value: 'meta' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Tiktok', value: 'tiktok' },
  { label: 'Snapchat', value: 'snapchat' },
  { label: 'Google display', value: 'googleDisplay' },
  { label: 'Youtube', value: 'youtube' },
  { label: 'Spotify', value: 'spotify' },
  { label: 'Reddit', value: 'reddit' },
  { label: 'Twitter', value: 'twitter' },
]
