// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3%;
}
.history .tableContainer {
  padding: 1% 0;
}
.history .densePadding {
  margin-top: 2%;
}`, "",{"version":3,"sources":["webpack://./src/components/AdvancedMenu/style.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEE;EACE,cAAA;AAAJ","sourcesContent":[".history {\n  .title {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 3%;\n  }\n  .tableContainer {\n    padding: 1% 0;\n  }\n  .densePadding {\n    margin-top: 2%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
