// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_header__u4QWF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.style_title__yck-6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  width: 260px;
}
.style_dialogTitle__vDLnQ {
  padding: 0px;
  color: #434243;
  font-size: 20px;
  font-weight: 700;
}

.style_status__-B-Sf {
  color: #ffc107;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--rs_yellow_10, #fff9e7);
  white-space: pre;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAEF;AAEA;EACE,YAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,oBAAA;EACA,wCAAA;EACA,gBAAA;AACF","sourcesContent":[".header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n.title {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 24px;\n  width: 260px;\n  h3 {\n  }\n}\n.dialogTitle {\n  padding: 0px;\n  color: #434243;\n  font-size: 20px;\n  font-weight: 700;\n}\n.status {\n  color: #ffc107;\n  font-size: 12px;\n  font-weight: 500;\n  margin-left: 10px;\n  display: flex;\n  padding: 4px 8px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  border-radius: 100px;\n  background: var(--rs_yellow_10, #fff9e7);\n  white-space: pre;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `style_header__u4QWF`,
	"title": `style_title__yck-6`,
	"dialogTitle": `style_dialogTitle__vDLnQ`,
	"status": `style_status__-B-Sf`
};
export default ___CSS_LOADER_EXPORT___;
