import useSWR, { SWRResponse } from 'swr'
import { useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useFormik } from 'formik'
import axios from 'axios'
import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import queries from '../../../graphQL/query'
import {
  CustomerTypeValues,
  customerTypes,
  customerValidationSchema,
} from '../create/CreateCustomer'
import {
  setCloseCustomerModal,
  setIsSaveForDialogTriggered,
  setTriggerCustomersRefetch,
} from '../../../store/slices/general'
import FileUploadArea from '../../../ui-component/fileUpload/FileUploadArea'
import { fixImgSrc, hasPermission } from '../../../utils/tools'
import FormErrorsModal from '../../../ui-component/FormErrorsModal'
import SuccessModal from '../../../ui-component/SuccessModal'
import { FileViewRow } from '../../../ui-component/fileViewRow/FileViewRow'
import { CUSTOMER_TYPES, TcreatCustomerObject, USER_ROLES } from '../../../constants'
import Loading from '../../../ui-component/loading/Loading'
import EmailRow from '../EditEmailRow'
import AddNewItem from '../../../ui-component/addNewItem/AddNewItem'
import CreateContactRow from '../CreateContactRow'
import EditPhoneRow from '../EditPhoneRow'
import { useSelector } from '../../../store/store'
import DialogWithUrlParams from '../../../components/DialogWithUrlParams/DialogWithUrlParams'
import BoxWrapper from '../../../components/BoxWrapper'
import { CustomTabPanel, a11yProps } from '../../../components/CustomerTabPanel/CustomTabPanel'
import { PermissionSettings } from '../../accountsAndPermissions/permissionSettings/PermissionSettings'
import ReadPermissions from '../../accountsAndPermissions/readPermissions/readPermissions'
import axiosInstance from '../../../utils/axiosInstance'
import { ReadCustomer } from './read/ReadCustomer'
import { TunsolvedAny } from '../../../types/unsolved'
import { Integrations } from './integrations/Integrations'

interface Iprops {
  businessCustomer?: string
}

const ReadEditCustomer: React.FC<Iprops> = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [, setMutationLoading] = useState(false)

  const dispatch = useDispatch()
  const { general, user } = useSelector((state) => state)
  const { isSaveForDialogTriggered } = general
  const { role } = user.userInfo

  const id = window.location.pathname.split('/')[2]

  const {
    data: fetchedData,
    isLoading,
    error,
  }: SWRResponse = useSWR(`/specificCustomerPermissions/${id}`, {
    fetcher: id ? axiosInstance : () => null,
  })
  const data = fetchedData?.data?.permissions

  const [value, setValue] = React.useState(0)
  const [permissions, setPermissions] = React.useState(data)

  useEffect(() => {
    if (data) setPermissions(data)
  }, [data])

  const { isEditActiveForDialog } = useSelector((state) => state.general)

  const handleClose = () => {
    const path = '/customers' // todo: use universal
    const { referrer } = document
    dispatch(setCloseCustomerModal({ path, referrer }))
  }
  const TabsContainer = (
    <Box>
      <Tabs value={value} onChange={(_e, v) => setValue(v)} aria-label="basic tabs example">
        <Tab label="Customer Details" {...a11yProps(0)} />
        {role === USER_ROLES.ADMIN.value && <Tab label="Integrations" {...a11yProps(1)} />}
        {(role === USER_ROLES.ADMIN.value ||
          role === USER_ROLES.EXTERNAL_USER_WHITE_LABEL.value) && (
          <Tab
            label="Customer Permission"
            {...a11yProps(role === USER_ROLES.ADMIN.value ? 2 : 1)}
          />
        )}
      </Tabs>
    </Box>
  )

  useEffect(() => {
    async function savePermissions() {
      try {
        setMutationLoading(true)
        await axiosInstance.post(`/specificCustomerPermissions/${id}`, { permissions })
        setSuccessModalOpen(true)
      } catch (error) {
        setErrorsModalOpen(true)
        setFormikErrors([String(error)])
      } finally {
        setMutationLoading(false)
      }
    }

    const isChanged = JSON.stringify(permissions) !== JSON.stringify(data)
    if (isChanged && isSaveForDialogTriggered && id) {
      savePermissions()
      dispatch(setIsSaveForDialogTriggered(false))
    }

    !id && window.location.reload()
  }, [isSaveForDialogTriggered, permissions, id, dispatch, data])

  if (!permissions) return <FormattedMessage id="not-admin" />
  if (isLoading) return <Loading />
  if (error) return <div>{error.message}</div>

  return (
    <div>
      <DialogWithUrlParams type="customer">
        {(id) => (
          <>
            <Container maxWidth="md" className="formContainer">
              <BoxWrapper>
                {TabsContainer}
                <div className="settingsCont marginTop">
                  <CustomTabPanel value={value} index={0}>
                    {isEditActiveForDialog ? <UpdateCustomer id={id} /> : <ReadCustomer id={id} />}
                  </CustomTabPanel>
                  {role === USER_ROLES.ADMIN.value && (
                    <CustomTabPanel value={value} index={1}>
                      <Integrations id={id} />
                    </CustomTabPanel>
                  )}
                  {(role === USER_ROLES.ADMIN.value ||
                    role === USER_ROLES.EXTERNAL_USER_WHITE_LABEL.value) && (
                    <CustomTabPanel value={value} index={role === USER_ROLES.ADMIN.value ? 2 : 1}>
                      <div className="customerDetails subFormContainer">
                        <div className="header">
                          <FormattedMessage id="customer-permissions" />
                        </div>
                        {isEditActiveForDialog ? (
                          <PermissionSettings data={permissions} setData={setPermissions} />
                        ) : (
                          <ReadPermissions permissions={permissions} />
                        )}
                      </div>
                    </CustomTabPanel>
                  )}
                </div>
              </BoxWrapper>
            </Container>
          </>
        )}
      </DialogWithUrlParams>
      <SuccessModal
        successModalOpen={successModalOpen}
        handleClose={handleClose}
        setSuccessModalOpen={setSuccessModalOpen}
        message="Updated successfully"
      />
      <FormErrorsModal
        errorsModalOpen={errorsModalOpen}
        formikErrors={formikErrors}
        setErrorsModalOpen={setErrorsModalOpen}
      />
    </div>
  )
}

export default ReadEditCustomer

const UpdateCustomer = ({ id }) => {
  const intl = useIntl()
  const [customerType, setCustomerType] = useState<CustomerTypeValues>('partner')
  const [formikErrors, setFormikErrors] = useState<string[]>([])
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [logoFile, setLogoFile] = useState<null | File>(null)
  const [logoFileName, setLogoFileName] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [addNewEmailClicked, setAddNewEmailClicked] = useState(false)
  const [addNewPhoneClicked, setAddNewPhoneClicked] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [newPhone, setNewPhone] = useState('')

  const { isSaveForDialogTriggered } = useSelector((state) => state.general)
  const { permissions, userInfo } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.customersDetailsFields)

  const { role } = userInfo
  const businessCustomer =
    role !== USER_ROLES.ADMIN.value && role !== USER_ROLES.INTERNAL_USER.value // todo

  const dispatch = useDispatch()

  const handleClose = () => {
    const path = '/customers' // todo: use universal
    const { referrer } = document
    dispatch(setCloseCustomerModal({ path, referrer }))
  }

  const { data: fetchedCustomerDetails, loading: loadingCustomerDetails } = useQuery(
    queries.customerDetails,
    {
      variables: { where: { id } },
    }
  )
  const customer = fetchedCustomerDetails?.customer

  const { data: fetchedAppUsers }: SWRResponse = useSWR('/app-users', axiosInstance)
  const appUsers = fetchedAppUsers?.data?.appUsers

  // contacts
  const [componentRendered, setComponentRendered] = useState(false)
  const [phones, setPhones] = useState<string[]>([])
  const [emails, setEmails] = useState<string[]>([])

  const [defaultInvoiceEmail, setDefaultInvoiceEmail] = useState('')
  const [defaultPreviewEmail, setDefaultPreviewEmail] = useState('')
  const [defaultEmail, setDefaultEmail] = useState('')

  useEffect(() => {
    if (customer?.emails && !componentRendered) {
      setEmails(JSON.parse(customer.emails))

      setDefaultEmail(customer.defaultEmail)
      setDefaultPreviewEmail(customer.defaultPreviewEmail)
      setDefaultInvoiceEmail(customer.defaultInvoiceEmail)

      setComponentRendered(true)
    }
    if (customer?.phoneNumbers) setPhones(JSON.parse(customer.phoneNumbers))
  }, [componentRendered, customer])

  const { data } = useQuery(queries.customers, {
    variables: {
      where: {
        type: {
          equals: 'partner',
        },
      },
    },
  })

  const resellerCustomers = data?.customers || []

  const onDropLogoFile = useCallback((acceptedFiles: [null] | File[]) => {
    // todo
    if (acceptedFiles?.length) {
      setLogoFile(acceptedFiles[0])
      if (acceptedFiles[0] && acceptedFiles[0].name) {
        setLogoFileName(acceptedFiles[0].name)

        // blob to base64
        const reader = new FileReader()
        reader.readAsDataURL(acceptedFiles[0])
        reader.onloadend = () => {
          setLogoUrl(reader.result as string)
        }
        // const src = `data:audio/mp3;base64,${data}`
        // setLogoUrl(URL.createObjectURL(src))
      }
    }
  }, [])

  const {
    acceptedFiles: acceptedFilesLogo,
    getRootProps: getRootPropsLogo,
    getInputProps: getInputPropsLogo,
    isDragActive: isDragActiveLogo,
  } = useDropzone({
    onDrop: onDropLogoFile,
  })

  const validationSchema = customerValidationSchema

  function removeLogo() {
    setLogoFile(null)
    setLogoFileName('')
  }
  async function fetchLogo(url: string) {
    const fetched = await axios({ method: 'GET', url: fixImgSrc(url), responseType: 'blob' }) //
    setLogoFile(fetched.data)
  }

  React.useEffect(() => {
    if (customer?.type) setCustomerType(customer.type)
  }, [customer?.type])

  React.useEffect(() => {
    if (customer?.logo) {
      fetchLogo(customer.logo.url)
      setLogoFileName('customer-logo')
      setLogoUrl(customer.logo.url)
    }
  }, [customer?.logo, customer?.logo?.url])

  const handleNewEmailApprove = useCallback(() => {
    setEmails([...emails, newEmail])
  }, [emails, newEmail])
  const handleNewPhoneApprove = useCallback(() => {
    setPhones([...phones, newPhone])
  }, [phones, newPhone])

  const formik = useFormik({
    initialValues: {
      name: customer?.name || '',
      url: customer?.url || '',
      address: customer?.address || '',
      automaticTransactionalEmails: customer?.automaticTransactionalEmails,
      adAccountId: customer?.adAccountId || '',
      type: customer?.type || customerTypes[0].value,
      connectedReseller: customer?.connectedReseller?.id || '',
      appUser: customer?.appUser?.id || '',
      logo: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      console.log('values', values)
      const newFormData: TcreatCustomerObject = {
        name: values.name,
        url: values.url,
        phoneNumbers: JSON.stringify(phones),
        emails: JSON.stringify(emails),
        automaticTransactionalEmails: values.automaticTransactionalEmails,
        adAccountId: values.adAccountId,
        defaultEmail,
        defaultInvoiceEmail,
        defaultPreviewEmail,
        address: values.address,
        type: values.type,
        connectedReseller: null,
        appUser: values.appUser ? { connect: { id: values.appUser } } : { disconnect: true },
        logo: null,
      }
      if (logoFile) {
        newFormData.logo = {
          upload: logoFile,
        }
      }

      if (!businessCustomer) {
        newFormData.type = values.type
      }

      if (values.type === 'resellerCustomer') {
        newFormData.connectedReseller = { connect: { id: values.connectedReseller } }
      } else {
        newFormData.connectedReseller = { disconnect: true }
      }
      console.log({ newFormData })
      const graphqlData = {
        variables: { data: newFormData, where: { id } },
      }
      const formData = new FormData()
      formData.append('graphqlData', JSON.stringify(graphqlData))
      if (logoFile) formData.append('logo', logoFile)
      try {
        const res = await axiosInstance.post(`/customer/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: { id },
        })
        if (res.data?.updateCustomer?.name) {
          setSuccessModalOpen(true)
          dispatch(setTriggerCustomersRefetch(true))
        }
      } catch (error) {
        const err = error as TunsolvedAny
        setFormikErrors([err.response?.data?.message])
        setErrorsModalOpen(true)
      }
      setIsLoading(false)
    },
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (isSaveForDialogTriggered) {
      const errors = Object.values(formik.errors)
      if (errors.length) {
        setErrorsModalOpen(true)
        setFormikErrors(errors as string[])
        return
      }
      if (logoFile) await formik.setFieldValue('invoice', { upload: logoFile })

      formik.handleSubmit()
    }
  }

  useEffect(() => {
    if (isSaveForDialogTriggered) {
      formik.handleSubmit()
      dispatch(setIsSaveForDialogTriggered(false))
    }
  }, [dispatch, formik, isSaveForDialogTriggered])

  if (loadingCustomerDetails || isLoading) return <Loading /> // todo: fix double loading spinner on mutation
  if (loadingCustomerDetails) return <Loading />

  return (
    <form onSubmit={handleSubmit}>
      <div className="formContainer">
        <Container maxWidth="md" className="customerDetails subFormContainer">
          <div className="header">
            <FormattedMessage id="customer-details" />
          </div>
          <div className="customerWithLogo">
            <div className="fields">
              <Grid container className="content">
                {permission('name') && customer.name && (
                  <TextField
                    fullWidth
                    label={intl.formatMessage({ id: 'customer-name' })}
                    id="name"
                    name="name"
                    placeholder={intl.formatMessage({ id: 'customer-name' })}
                    type="name"
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    defaultValue={customer?.name}
                    // sx={{ paddingBottom: businessCustomer ? 2 : 'inherit' }}
                  />
                )}
                {permission('type') && !businessCustomer && (
                  <FormControl
                    // sx={{ marginBottom: businessCustomer ? 2 : 'inherit' }}
                    fullWidth
                  >
                    <InputLabel id="type">
                      <FormattedMessage id="type" />
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="type"
                      id="type"
                      name="type"
                      label={intl.formatMessage({ id: 'type' })}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setCustomerType(e.target.value)
                      }}
                      defaultValue={customer.type}
                    >
                      {Object.keys(CUSTOMER_TYPES).map((key) => (
                        <MenuItem key={key} value={CUSTOMER_TYPES[key].value}>
                          {CUSTOMER_TYPES[key].label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {permission('connectedReseller') &&
                  customer &&
                  customerType === 'resellerCustomer' && (
                    <FormControl
                      // sx={{ paddingBottom: businessCustomer ? 2 : 'inherit' }}
                      fullWidth
                    >
                      <InputLabel id="connectedReseller">
                        <FormattedMessage id="connected-reseller" />
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="connectedReseller"
                        id="connectedReseller"
                        name="connectedReseller"
                        label={intl.formatMessage({ id: 'connected-reseller' })}
                        onChange={formik.handleChange}
                        defaultValue={customer.connectedReseller?.id}
                      >
                        {resellerCustomers.map((i: { name: string; id: string }) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                {/* {permission('connectedAppUser') && ( */}
                <Autocomplete
                  fullWidth
                  options={appUsers}
                  getOptionLabel={(option: { firstName: string; email: string; id: string }) =>
                    `${option.firstName} ${option.firstName && '-'} ${option.email}`
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue('appUser', value?.id)
                  }}
                  defaultValue={fetchedCustomerDetails?.customer?.appUser}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<FormattedMessage id="appUser" />}
                      id="appUser"
                      name="appUser"
                      placeholder="App User"
                      type="text"
                      value={formik.values.appUser}
                      onChange={formik.handleChange}
                      error={formik.touched.appUser && Boolean(formik.errors.appUser)}
                    />
                  )}
                />
                {/* )} */}

                {permission('address') && customer.name && (
                  <TextField
                    fullWidth
                    label={intl.formatMessage({ id: 'address' })}
                    id="address"
                    name="address"
                    placeholder={intl.formatMessage({ id: 'address' })}
                    type="address"
                    onChange={formik.handleChange}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    defaultValue={customer.address}
                  />
                )}

                {permission('url') && !businessCustomer && (
                  <TextField
                    fullWidth
                    label="Url"
                    id="url"
                    name="url"
                    placeholder="url"
                    type="url"
                    onChange={formik.handleChange}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    defaultValue={customer.url}
                  />
                )}
                {(role === USER_ROLES.ADMIN.value || role === USER_ROLES.INTERNAL_USER.value) && (
                  <TextField
                    fullWidth
                    id="adAccountId"
                    name="adAccountId"
                    placeholder={intl.formatMessage({ id: 'ad-account-id' })}
                    type="adAccountId"
                    onChange={formik.handleChange}
                    error={formik.touched.adAccountId && Boolean(formik.errors.adAccountId)}
                    defaultValue={customer.adAccountId}
                  />
                )}
                {permission('automaticTransactionalEmails') && (
                  <FormGroup className="checkboxes">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.automaticTransactionalEmails}
                          defaultChecked={customer.automaticTransactionalEmails}
                          onChange={formik.handleChange}
                          name="automaticTransactionalEmails"
                        />
                      }
                      label={intl.formatMessage({ id: 'auto-transactional-emails' })}
                    />
                  </FormGroup>
                )}
              </Grid>
            </div>
            {permission('logo') && (
              <div className="logo">
                <div className="content">
                  <FileUploadArea
                    title="Logo"
                    getInputProps={getInputPropsLogo}
                    getRootProps={getRootPropsLogo}
                    acceptedFiles={acceptedFilesLogo}
                    isDragActive={isDragActiveLogo}
                    // withResults
                  />
                </div>
                {customer.logo?.url && logoFile && (
                  <div className="thumbnailContainer">
                    <FileViewRow
                      fileSource={logoUrl}
                      onRemove={removeLogo}
                      fileName={logoFileName}
                      hasPreview
                      fixSrc={false}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {permission('emails') && (
            <>
              <div className="header">
                <FormattedMessage id="email" />
              </div>
              <Grid container className="content">
                {emails[0] ? (
                  emails.map((email) => (
                    <EmailRow
                      email={email}
                      emails={emails}
                      setEmails={setEmails}
                      key={email}
                      defaultInvoiceEmail={defaultInvoiceEmail}
                      setDefaultInvoiceEmail={setDefaultInvoiceEmail}
                      defaultPreviewEmail={defaultPreviewEmail}
                      setDefaultPreviewEmail={setDefaultPreviewEmail}
                      defaultEmail={defaultEmail}
                      setDefaultEmail={setDefaultEmail}
                    />
                  ))
                ) : (
                  <div />
                )}
                <div className="newContactCont">
                  {addNewEmailClicked && (
                    <div>
                      <div className="newEmail">
                        <CreateContactRow
                          type="email"
                          setContact={setNewEmail}
                          setItemClicked={() => setAddNewEmailClicked(false)}
                          handleApprove={() => handleNewEmailApprove()}
                        />
                      </div>
                    </div>
                  )}
                  <AddNewItem item="E-mail" handleAddItem={() => setAddNewEmailClicked(true)} />
                </div>
              </Grid>
            </>
          )}

          {permission('phoneNumbers') && (
            <>
              <div className="header">
                <FormattedMessage id="phone" />
              </div>
              <Grid container className="content">
                {phones.length ? (
                  phones.map((phone) => (
                    <EditPhoneRow key={phone} phone={phone} setPhones={setPhones} />
                  ))
                ) : (
                  <div />
                )}
                <div className="newContactCont">
                  {addNewPhoneClicked && (
                    <div>
                      <div className="newEmail">
                        <CreateContactRow
                          type="phone"
                          setContact={setNewPhone}
                          setItemClicked={() => setAddNewPhoneClicked(false)}
                          handleApprove={() => handleNewPhoneApprove()}
                        />
                      </div>
                    </div>
                  )}
                  <AddNewItem
                    item="Phone number"
                    handleAddItem={() => setAddNewPhoneClicked(true)}
                  />
                </div>
              </Grid>
            </>
          )}
        </Container>

        <FormErrorsModal
          errorsModalOpen={errorsModalOpen}
          formikErrors={formikErrors}
          setErrorsModalOpen={setErrorsModalOpen}
        />
        <SuccessModal
          successModalOpen={successModalOpen}
          handleClose={handleClose}
          setSuccessModalOpen={setSuccessModalOpen}
          message="Updated successfully"
        />
      </div>
    </form>
  )
}
