// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__RMvVz {
  overflow-y: auto;
  max-height: 410px;
  padding-right: 16px;
}
.style_wrapper__RMvVz::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}
.style_wrapper__RMvVz::-webkit-scrollbar-thumb {
  background-color: #a4a8b3;
  border-radius: 25px;
}
.style_wrapper__RMvVz::-webkit-scrollbar-thumb:hover {
  background-color: #a4a8b3;
}

.style_createLead__ikUYY {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_title__nkbKu {
  margin: 20px auto;
}`, "",{"version":3,"sources":["webpack://./src/views/leads/leadsList/leadDetail/style.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACE;EACE,UAAA;EACA,sBAAA;AACJ;AAEE;EACE,yBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,iBAAA;AAFF","sourcesContent":[".wrapper {\n  overflow-y: auto;\n  max-height: 410px;\n  padding-right: 16px;\n\n  &::-webkit-scrollbar {\n    width: 4px;\n    background-color: #fff;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #a4a8b3;\n    border-radius: 25px;\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    background-color: #a4a8b3;\n  }\n}\n\n.createLead {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.title {\n  margin: 20px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__RMvVz`,
	"createLead": `style_createLead__ikUYY`,
	"title": `style_title__nkbKu`
};
export default ___CSS_LOADER_EXPORT___;
