import { Grid } from '@mui/material'
import MainCard from '../../ui-component/extended/cards/MainCard'
import JobsTable from './table'

const Jobs = () => {
  return (
    <MainCard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <JobsTable />
        </Grid>
      </Grid>
    </MainCard>
  )
}

export default Jobs
