// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monthWrapper {
  display: flex;
  justify-content: space-between;
}
.monthWrapper p {
  font-weight: 700;
  color: #757575;
  margin: 0;
}

.yearlyGraphWrap .recharts-default-legend {
  margin: 24px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/YearlyGraph/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AAAE;EACE,gBAAA;EACA,cAAA;EACA,SAAA;AAEJ;;AAEE;EACE,yBAAA;AACJ","sourcesContent":[".monthWrapper {\n  display: flex;\n  justify-content: space-between;\n  p {\n    font-weight: 700;\n    color: #757575;\n    margin: 0;\n  }\n}\n.yearlyGraphWrap {\n  .recharts-default-legend {\n    margin: 24px 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
