// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_timeline__XiUHO {
  margin: 16px 0;
  display: flex;
}
.style_timeline__XiUHO h2,
.style_timeline__XiUHO h3,
.style_timeline__XiUHO h4,
.style_timeline__XiUHO h5,
.style_timeline__XiUHO p {
  margin: 0;
}
.style_timeline__XiUHO h3 {
  font-size: 16px;
  margin-bottom: 12px;
}

.style_current__blEVC {
  border: 2px solid #220d77;
  padding: 8px;
  box-sizing: content-box;
}

.style_dot__B9507 {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  color: #220d77;
}

.style_line__BbIQp {
  border-left: 2px solid #220d77;
  height: 100px;
  width: fit-content;
  display: block;
  position: relative;
  left: 14px;
  top: -5px;
}

.style_activity__tWYDz {
  margin-left: 12px;
}`, "",{"version":3,"sources":["webpack://./src/views/leads/leadsList/leadDetail/timeline/style.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;AACF;AACE;;;;;EAKE,SAAA;AACJ;AACE;EACE,eAAA;EACA,mBAAA;AACJ;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,uBAAA;AADF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,oBAAA;EACA,cAAA;AAAF;;AAEA;EACE,8BAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;AACF;;AACA;EACE,iBAAA;AAEF","sourcesContent":[".timeline {\n  margin: 16px 0;\n  display: flex;\n\n  h2,\n  h3,\n  h4,\n  h5,\n  p {\n    margin: 0;\n  }\n  h3 {\n    font-size: 16px;\n    margin-bottom: 12px;\n  }\n}\n.timelineLine {\n}\n.current {\n  border: 2px solid #220d77;\n  padding: 8px;\n  box-sizing: content-box;\n}\n.dot {\n  height: 10px;\n  width: 10px;\n  border-radius: 100px;\n  color: #220d77;\n}\n.line {\n  border-left: 2px solid #220d77;\n  height: 100px;\n  width: fit-content;\n  display: block;\n  position: relative;\n  left: 14px;\n  top: -5px;\n}\n.activity {\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeline": `style_timeline__XiUHO`,
	"current": `style_current__blEVC`,
	"dot": `style_dot__B9507`,
	"line": `style_line__BbIQp`,
	"activity": `style_activity__tWYDz`
};
export default ___CSS_LOADER_EXPORT___;
