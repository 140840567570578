// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leadsPerMonthGraph {
  border-radius: 6px;
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;
}

.stats {
  display: flex;
  justify-content: space-around;
}
.stats .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stats .item p {
  color: #757575;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0;
  margin-bottom: 4px;
}
.stats .item span {
  color: #434243;
  font-size: 20px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/views/leads/leadsPerMonth/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,6BAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,6BAAA;AAEF;AADE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAGJ;AAFI;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,SAAA;EACA,kBAAA;AAIN;AAFI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAIN","sourcesContent":[".leadsPerMonthGraph {\n  border-radius: 6px;\n  border-bottom: 1px solid #eee;\n  margin-bottom: 16px;\n}\n.stats {\n  display: flex;\n  justify-content: space-around;\n  .item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    p {\n      color: #757575;\n      font-family: Roboto;\n      font-size: 10px;\n      font-weight: 400;\n      letter-spacing: 0.3px;\n      margin: 0;\n      margin-bottom: 4px;\n    }\n    span {\n      color: #434243;\n      font-size: 20px;\n      font-weight: 700;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
