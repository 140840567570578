// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeHolderRender {
  border-radius: 2px;
  display: flex !important;
}

.noOpenOrders {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.noOpenOrders p {
  color: #a4a8b3;
  font-size: 24px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/PlaceHolderRender/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,wBAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".placeHolderRender {\n  border-radius: 2px;\n  display: flex !important;\n}\n\n.noOpenOrders {\n  background-color: #f5f5f5;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  p {\n    color: #a4a8b3;\n    font-size: 24px;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
