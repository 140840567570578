// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignInSignUp {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SignInSignUp .supertokens {
  position: relative;
}
.SignInSignUp .supertokens .brand {
  position: absolute;
  top: 35px;
  width: 420px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/views/auth/signInSignp/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,kBAAA;AAGN","sourcesContent":[".SignInSignUp {\n  margin-top: 5%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  .supertokens {\n    position: relative;\n    .brand {\n      position: absolute;\n      top: 35px;\n      width: 420px;\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
