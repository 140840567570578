import { USER_ROLES } from './user'

export const USER_GROUP = {
  ALL: [
    USER_ROLES.ADMIN.value,
    USER_ROLES.INTERNAL_USER.value,
    USER_ROLES.INTERNAL_USER_LIMITED.value,
    USER_ROLES.EXTERNAL_DIRECT_CUSTOMER.value,
    USER_ROLES.EXTERNAL_RESELLER_CUSTOMER.value,
    USER_ROLES.EXTERNAL_RESELLER.value,
    USER_ROLES.EXTERNAL_USER_WHITE_LABEL.value,
  ],
  ADMIN_ONLY: [USER_ROLES.ADMIN.value],
}

export const PAGE = {
  CUSTOMERS: {
    PATH: '/customers',
    ROLES: USER_GROUP.ALL,
  },
  USER: {
    PATH: '/user',
    ROLES: USER_GROUP.ALL,
  },
  CAMPAIGNS: {
    PATH: '/campaigns',
    ROLES: USER_GROUP.ALL,
  },
  APPROVE_ORDER: {
    PATH: '/approve-order',
    ROLES: USER_GROUP.ALL,
  },
  ORDERS: {
    PATH: '/orders',
    ROLES: USER_GROUP.ALL,
  },
  JOBS: {
    PATH: '/jobs',
    ROLES: USER_GROUP.ADMIN_ONLY,
  },
  ORDER_PREVIEWS: {
    PATH: '/order-previews',
    ROLES: USER_GROUP.ALL,
  },
  DASHBOARD: {
    PATH: '/dashboard',
    ROLES: USER_GROUP.ALL,
  },
  LEADS: {
    PATH: '/leads',
    ROLES: USER_GROUP.ALL,
  },
  ACCOUNTS_AND_PERMISSIONS: {
    ACCOUNTS: {
      PATH: '/accounts',
    },
    PERMISSIONS: {
      PATH: '/permissions',
    },
    ROLES: USER_GROUP.ALL,
  },
  ANALYTICS: {
    PATH: '/analytics',
    ROLES: USER_GROUP.ALL,
  },
}
