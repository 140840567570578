// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: Rubik;
}

.fill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
}

.sessionButton {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

.divider, .divider-noline {
  margin: 5% 0;
  width: 100%;
  border-bottom: rgba(182, 182, 182, 0.5) solid 1px;
}

.divider-noline {
  border-bottom: unset;
}

.marginTop {
  margin-top: 5%;
}

.css-qnn720-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
  border-bottom: unset;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.navSubChild {
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,iDAAA;AACF;;AAEA;EAEE,oBAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAIA;EACE,UAAA;EACA,WAAA;AADF;;AAIA;EACE,yBAAA;EACA,kBAAA;AADF;;AAIA;EACE,sBAAA;EACA,kBAAA;AADF;;AAIA;EACE,sBAAA;AADF;;AAIA;EACE,kBAAA;AADF","sourcesContent":[".App {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  font-family: Rubik;\n}\n\n.fill {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1;\n}\n\n.sessionButton {\n  padding-left: 13px;\n  padding-right: 13px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  background-color: black;\n  border-radius: 10px;\n  cursor: pointer;\n  color: white;\n  font-weight: bold;\n  font-size: 17px;\n}\n\n.divider {\n  margin: 5% 0;\n  width: 100%;\n  border-bottom: rgba($color: #b6b6b625, $alpha: 0.5) solid 1px;\n}\n\n.divider-noline {\n  @extend .divider;\n  border-bottom: unset;\n}\n\n.marginTop {\n  margin-top: 5%;\n}\n\n.css-qnn720-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {\n  border-bottom: unset;\n}\n\n// scrollbar\n*::-webkit-scrollbar {\n  width: 8px;\n  height: 4px;\n}\n\n*::-webkit-scrollbar-track {\n  background-color: #f2f2f2;\n  border-radius: 8px;\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 8px;\n}\n\n*::-webkit-scrollbar-thumb:hover {\n  background-color: #555;\n}\n\n.navSubChild {\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
