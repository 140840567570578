/* eslint-disable */
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import DialogBox from '../../../../components/Dialog'
// import Notes from './notes'
import Timeline from './timeline'
import classes from './style.module.scss'
import queries from '../../../../graphQL/query'
import Loading from '../../../../ui-component/loading/Loading'
import { leadStatuses } from '../../../../constants'
import MultiImageEdit from '../../../../ui-component/multiImageEdit/MultiImageEdit'
import { IdataSourceItem, IfileItem } from '../../../../types/other'
import { fixImgSrc, hasPermission } from '../../../../utils/tools'
import FormErrorsModal from '../../../../ui-component/FormErrorsModal'
import SuccessModal from '../../../../ui-component/SuccessModal'
import { TgraphQlFile } from '../../../../types'
import { useDispatch, useSelector } from '../../../../store/store'
import { dialogToggle } from '../../../../store/slices/dialog'
import axiosInstance from '../../../../utils/axiosInstance'
import { setTrigger } from '../../../../store/slices/lead'

function ReadEditLead() {
  const intl = useIntl()
  const { open } = useSelector((state) => state.dialog)
  const [title, setTitle] = useState('Details')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [questions, setQuestions] = useState([])
  const [formatQuestion, setFormatQuestion] = useState({})
  const [files, setFiles] = React.useState<IfileItem[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const { id: campaignId } = useParams()
  const id = searchParams.get('lead') || null
  const isUpdate = !!id
  const dispatch = useDispatch()
  const [idsToDelete, setIdsToDelete] = React.useState<string[]>([])
  const [idsToCreate, setIdsToCreate] = React.useState<string[]>([])
  const [dataSources, setDataSources] = React.useState<IdataSourceItem[]>([])

  const [formikErrors, setFormikErrors] = React.useState<string[]>([])
  const [errorsModalOpen, setErrorsModalOpen] = React.useState(false)
  const [successModalOpen, setSuccessModalOpen] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = React.useState('')
  const [platform, setPlatform] = React.useState('')
  const [campaignData, setCampaignData] = React.useState({
    id: '',
    campaignTitle: '',
    questions: '',
  })

  const { permissions } = useSelector((state) => state.user)
  const permission = hasPermission(permissions?.leadsAndTalentsDetailsFields)

  // Create
  useEffect(() => {
    // Get campaign questions
    axiosInstance
      .get('/campaign', {
        params: { id: campaignId },
      })
      .then((res) => {
        const { id, campaignTitle, questions } = res.data

        setCampaignData({ id, campaignTitle, questions })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }, [campaignId])

  const removeParams = useCallback(() => {
    setSearchParams((params) => {
      params.delete('lead')
      return params
    })
  }, [setSearchParams])

  const handleCloseCB = useCallback(() => {
    dispatch(dialogToggle(false))
    removeParams()
  }, [dispatch, removeParams])

  const handleCloseSuccess = useCallback(() => {
    setSuccessModalOpen(false)

    removeParams()
    dispatch(dialogToggle(false))
  }, [dispatch, removeParams])

  const { data: fetchedLeadDetails, loading: loadingLeadDetails } = useQuery(queries.readLead, {
    variables: { where: { id: { equals: id } } },
    fetchPolicy: 'no-cache',
  })

  const [mutateFunction, { loading: loadingMutation, error: mutationError }] = useMutation(
    queries.updateLead
  )
  // isLoading
  const [createLead, { error: mutationErrorCreate, loading: loadingCreateLead }] = useMutation(
    queries.createLead
  )
  const [connectCampaignWithLead, { error: mutationErrorConnectCampaignWithLead }] = useMutation(
    queries.connectCampaignWithLead
  )

  const [deleteLeadFile] = useMutation(queries.deleteLeadFile)

  useEffect(() => {
    if (mutationError) {
      setFormikErrors(mutationError.graphQLErrors.map((i) => i.message))
      setErrorsModalOpen(true)
    }
    if (mutationErrorCreate) {
      setFormikErrors(mutationErrorCreate.graphQLErrors.map((i) => i.message))
      setErrorsModalOpen(true)
    }
    if (mutationErrorConnectCampaignWithLead) {
      setFormikErrors(mutationErrorConnectCampaignWithLead.graphQLErrors.map((i) => i.message))
      setErrorsModalOpen(true)
    }
  }, [mutationError, mutationErrorCreate, mutationErrorConnectCampaignWithLead])

  useEffect(() => {
    return () => {
      setPhoneNumber('')
      setEmail('')
      setQuestions([])
      setTitle('')
    }
  }, [])

  // View/edit
  useEffect(() => {
    if (fetchedLeadDetails) {
      const fieldData = JSON.parse(fetchedLeadDetails.metaLeads[0].fieldData).map((item) => ({
        [item.name]: item.values[0],
      }))
      const {
        full_name: fullName,
        phone_number: phoneNumber,
        email,
        ...questions
      } = Object.assign({}, ...fieldData)

      setPhoneNumber(phoneNumber)
      setEmail(email)
      setQuestions(questions)
      setTitle(fullName)
    }
  }, [fetchedLeadDetails])

  const deleteLead = useCallback(
    async (id: string) => {
      await deleteLeadFile({
        variables: {
          where: { id },
        },
      })
    },
    [deleteLeadFile]
  )

  const validationSchema = yup.object({
    status: yup
      .string()
      .required()
      .oneOf(leadStatuses.map((i) => i.value)),
    question: yup.string().nullable(),
    metaNotes: yup.string().nullable(),
    metaLeadFiles: yup.object().nullable(),
  })

  useEffect(() => {
    if (fetchedLeadDetails?.metaLeads[0].metaLeadFiles?.length) {
      const metaLeadFiles = fetchedLeadDetails?.metaLeads[0].metaLeadFiles.map(
        (i: TgraphQlFile) => ({
          id: i?.id,
          url: fixImgSrc(i.file.url),
          name: i.name,
        })
      )
      setDataSources(metaLeadFiles)
    }
  }, [fetchedLeadDetails])

  const formik = useFormik({
    initialValues: {
      status: status || fetchedLeadDetails?.metaLeads[0]?.status,
      metaNotes: fetchedLeadDetails?.metaLeads[0]?.metaNotes,
      metaLeadFiles: null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const newFormData = {
        status: values.status,
        metaNotes: values.metaNotes,
        metaLeadFiles: values.metaLeadFiles,
      }
      const res = await mutateFunction({ variables: { data: newFormData, where: { id } } })
      if (res.data?.updateMetaLead?.status) {
        // addChangeLogToTaskHistory(
        //   createOrderHistory,
        //   fetchedTaskDetails.task,
        //   res.data.updateTask,
        //   userInfo.id
        // )
        if (idsToDelete?.length) {
          await Promise.all(idsToDelete.map((id) => deleteLead(id)))
        }

        dispatch(dialogToggle(false))
        dispatch(setTrigger(true))
        setSuccessModalOpen(true)
      }
    },
  })

  const formikCreate = useFormik({
    initialValues: {
      status,
      metaNotes: fetchedLeadDetails?.metaLeads[0]?.metaNotes,
      metaLeadFiles: null,
      campaign: { connect: { id: campaignId } },
      platform,
      createdTime: '',
      fieldData: [
        { name: 'phone_number', values: [phoneNumber] },
        { name: 'email', values: [email] },
        { name: 'full_name', values: [fullName] },
        { name: 'phone_number', values: [phoneNumber] },
        ...Object.entries(formatQuestion).map((item) => ({ name: item[0], values: [item[1]] })),
      ],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const newFormData = {
        campaign: values.campaign,
        status: values.status,
        fieldData: JSON.stringify(values.fieldData),
        platform: values.platform,
        createdTime: new Date().toISOString(),
        metaLeadFiles: values.metaLeadFiles,
      }

      const res = await createLead({ variables: { data: newFormData, where: { id } } })
      const leadId = res.data.createMetaLead.id

      await connectCampaignWithLead({
        variables: {
          data: {
            leads: { connect: { id: leadId } },
          },
          where: { id: campaignId },
        },
      })
      if (res) {
        dispatch(setTrigger(true))
        setSuccessModalOpen(true)
      }
    },
  })

  async function handleSubmit() {
    if (isUpdate) {
      const errors = Object.values(formik.errors)
      if (errors?.length) {
        setErrorsModalOpen(true)
        setFormikErrors(errors as string[])
        return
      }
      if (files) {
        await formik.setFieldValue('metaLeadFiles', {
          create: files.map((i: IfileItem) => ({
            name: i.file.name,
            file: {
              upload: i.file,
            },
          })),
        })
      }

      return formik.handleSubmit()
    }

    const errors = Object.values(formikCreate.errors)
    if (errors?.length) {
      setErrorsModalOpen(true)
      setFormikErrors(errors as string[])
      return
    }
    if (files) {
      await formikCreate.setFieldValue('metaLeadFiles', {
        create: files.map((i: IfileItem) => ({
          name: i.file.name,
          file: {
            upload: i.file,
          },
        })),
      })
    }

    formikCreate.handleSubmit()
  }

  const RenderQuestions = useCallback(() => {
    // useCallback
    const formatQuestion = Object.entries(questions).map((question) => ({
      name: question[0].split('_').join(' '),
      value: question[1],
    }))

    const campaignQuestion = JSON.parse(campaignData?.questions || '[]')
      .filter((item) => item.type === 'CUSTOM')
      .map(({ id, key, label }) => ({ id, key, name: label, value: '' }))

    return (
      <>
        {(isUpdate ? formatQuestion.reverse() : campaignQuestion)?.map(
          ({ name, value = '', key = '' }) => (
            <Grid item xs={12} key={name}>
              <TextField
                label={name}
                value={value || formatQuestion[key]} // isUpdate ? value : questionState.value
                name={key}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setFormatQuestion((old) => ({ ...old, [e.target.name]: e.target.value }))
                }}
                multiline={value?.length > 55}
              />
            </Grid>
          )
        )}
      </>
    )
  }, [campaignData.questions, isUpdate, questions])

  const generalPermission = permissions?.leadsAndTalents
  generalPermission && null

  return (
    <DialogBox
      open={open}
      title={isUpdate ? title : 'Create Lead'}
      setOpen={() => handleCloseCB()}
      handleSubmit={() => handleSubmit()}
      isLoading={loadingLeadDetails || loadingMutation || loadingCreateLead || successModalOpen}
    >
      {loadingLeadDetails || loadingMutation || loadingCreateLead ? (
        <Loading />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} container rowSpacing={2}>
              {permission('status') && (
                <Grid item xs={12} container rowSpacing={2} columnSpacing={2} width={'100%'}>
                  <Grid item xs={isUpdate ? 12 : 6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="status"
                        name="status"
                        defaultValue={fetchedLeadDetails?.metaLeads[0]?.status || ''}
                        onChange={(e) => setStatus(e.target.value)}
                        label={intl.formatMessage({ id: 'status' })}
                        style={{ width: '100%' }}
                        // onChange={formik.handleChange}
                      >
                        {leadStatuses.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    {!isUpdate && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="status"
                          name="status"
                          defaultValue={platform}
                          onChange={(e) => setPlatform(e.target.value)}
                          label={intl.formatMessage({ id: 'platform' })}
                          style={{ width: '100%' }}
                          // onChange={formik.handleChange}
                        >
                          {[
                            { name: 'Facebook', value: 'fb' },
                            { name: 'Instagram', value: 'ig' },
                          ].map((channel) => (
                            <MenuItem key={channel.name} value={channel.value}>
                              {channel.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              )}
              {!isUpdate && permission('name') && (
                <Grid item xs={12}>
                  <TextField
                    label={intl.formatMessage({ id: 'full-name' })}
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    variant="outlined"
                    name="fullName"
                    fullWidth
                    multiline={fullName?.length > 55}
                  />
                </Grid>
              )}
              <Grid item container spacing={2} xs={12}>
                {permission('phone') && (
                  <Grid item xs={12}>
                    <TextField
                      label={intl.formatMessage({ id: 'phone' })}
                      placeholder="Telefon"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      variant="outlined"
                      fullWidth
                      multiline={phoneNumber?.length > 55}
                    />
                  </Grid>
                )}
                {permission('email') && (
                  <Grid item xs={12}>
                    <TextField
                      label={intl.formatMessage({ id: 'email' })}
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      fullWidth
                      multiline={email?.length > 55}
                    />
                  </Grid>
                )}
              </Grid>
              <RenderQuestions />
            </Grid>

            <Grid item xs={6} className={classes.wrapper}>
              {permission('metaLeadFiles') && (
                <>
                  <Typography variant="h4">Files</Typography>
                  <MultiImageEdit
                    title="Files"
                    setFiles={setFiles}
                    setDataSources={setDataSources}
                    dataSources={dataSources}
                    idsToCreate={idsToCreate}
                    idsToDelete={idsToDelete}
                    setIdsToDelete={setIdsToDelete}
                    setIdsToCreate={setIdsToCreate}
                    hasPreview={false}
                    isMultiType
                    showButton={false}
                  />
                </>
              )}
              {<Timeline data={[]} />}
            </Grid>
          </Grid>
        </>
      )}

      <FormErrorsModal
        errorsModalOpen={errorsModalOpen}
        formikErrors={formikErrors}
        setErrorsModalOpen={setErrorsModalOpen}
      />
      <SuccessModal
        successModalOpen={successModalOpen}
        handleClose={() => handleCloseSuccess()}
        setSuccessModalOpen={setSuccessModalOpen}
        message={isUpdate ? 'Edited successfully' : 'Created successfully'}
      />
    </DialogBox>
  )
}

export default ReadEditLead
