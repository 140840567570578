import { Button, Container, Dialog, DialogActions, DialogContent, Tooltip } from '@mui/material'
import DialogHeader from './header'

type Props = {
  children: any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  title: string
  handleSubmit: () => void
  additionalAction?: {
    label: string
    onClick: () => void
    disabled?: boolean
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  }
  additionalActionTooltip?: string
}

export default function DialogBox({
  children,
  open = false,
  setOpen,
  title = '',
  isLoading = false,
  handleSubmit,
  additionalAction,
  additionalActionTooltip,
}: Props) {
  const handleClose = () => {
    setOpen((old) => !old)
  }

  return (
    <>
      <Dialog
        open={open}
        className="ReadEditOrder"
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            handleClose()
          }
        }}
      >
        <Container disableGutters>
          <DialogHeader title={title} handleClose={handleClose} />
          <DialogContent sx={{ overflow: 'hidden' }}>{children}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            {additionalAction &&
              (additionalActionTooltip && additionalAction.disabled ? (
                <Tooltip title={additionalActionTooltip} arrow placement="top">
                  <span>
                    <Button
                      variant="contained"
                      color={additionalAction.color || 'primary'}
                      onClick={additionalAction.onClick}
                      disabled={additionalAction.disabled || isLoading}
                      sx={{ marginLeft: '10px' }}
                    >
                      {additionalAction.label}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  variant="contained"
                  color={additionalAction.color || 'primary'}
                  onClick={additionalAction.onClick}
                  disabled={additionalAction.disabled || isLoading}
                  sx={{ marginLeft: '10px' }}
                >
                  {additionalAction.label}
                </Button>
              ))}
            <Button
              variant="contained"
              onClick={() => {
                handleSubmit()
              }}
              disabled={isLoading}
            >
              Save
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  )
}
