// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permissionRow {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/views/accountsAndPermissions/readPermissions/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF","sourcesContent":[".permissionRow {\n  padding: 10px 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
