import React from 'react'
import Snackbar from './ui-component/extended/Snackbar'
import ThemeRoutes from './routes'
import { useDispatch, useSelector } from './store/store'
import { setPermissions, setUserInfo } from './store/slices/user'
import Unauthorized from './views/errors/401'
import configs from './config/configs'
import ReadEditCustomer from './views/customers/readEdit/ReadEditCustomer'
import { TresolvedAny } from './types'
import axiosInstance from './utils/axiosInstance'
import { USER_ROLES } from './constants'

const { userInfo } = configs
const { shouldUseMockedData } = configs

export const SessionContainer = () => {
  const dispatch = useDispatch()
  const [res, setRes] = React.useState<TresolvedAny>(null)

  const { isCustomerModalOpen } = useSelector((state) => state.general)

  React.useEffect(() => {
    async function getUserInfo(): Promise<TresolvedAny> {
      let user: TresolvedAny = null
      let permissions: TresolvedAny = null
      if (!shouldUseMockedData) {
        // todo: if shouldUseMockedData
        try {
          const { data } = await axiosInstance('getUserInfo')
          setRes(data)
          if (data?.userInfo) {
            user = data.userInfo
            permissions = data.permissions
            const isUserRoleInternal = [
              USER_ROLES.ADMIN.value,
              USER_ROLES.INTERNAL_USER.value,
            ].includes(user.role)

            dispatch(setUserInfo({ ...user, isUserRoleInternal }))
            dispatch(setPermissions(permissions))
          }
        } catch (error: any) {
          setRes(error?.response.data)
          console.error(`Failed to get user info from: ${error}`)
        }
      } else {
        setRes(userInfo)
        user = userInfo
        permissions = null // todo
      }

      return { userInfo: user, permissions }
    }

    getUserInfo()
  }, [dispatch])

  if (!res) return null
  // # todo
  if (res?.status === 404) return <Unauthorized message={res.message} />

  // # todo
  if (res?.code === 1001) return <Unauthorized message={res.message} />

  return (
    <>
      <ThemeRoutes />
      <Snackbar />
      {isCustomerModalOpen && <ReadEditCustomer />}
    </>
  )
}

// todo : remove
//   type Toutput =
//     | {
//         role: string
//         firstName: string
//         lastName: string
//         email: string
//         phoneNumber: string
//         address: string
//         logo: string
//       } & { status: number; message: string }
