// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditEmailRow {
  border-bottom: solid 1px lightgray;
  width: 100%;
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  padding: 2.5% 1%;
  padding-right: 0;
}
.EditEmailRow .email {
  display: flex;
  align-items: center;
  justify-content: center;
}
.EditEmailRow .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.EditEmailRow .row .options {
  width: 60%;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
}
.EditEmailRow .row .options > * {
  display: flex;
  align-items: center;
  justify-content: center;
}
.EditEmailRow .row .options > div {
  padding: 0 5%;
  border-right: solid 2px lightgray;
}
.EditEmailRow .row .options > div .setItem {
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
}
.EditEmailRow .row .options > div .setItem:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/views/customers/style.scss"],"names":[],"mappings":"AACA;EACE,kCAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAEE;EACE,WAAA;EACA,aAAA;EACA,8BAAA;AAAJ;AACI;EAME,UAAA;EACA,iBAAA;EACA,aAAA;EACA,wCAAA;AAJN;AAJM;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAMR;AACM;EACE,aAAA;EACA,iCAAA;AACR;AAAQ;EACE,iBAAA;EACA,mBAAA;EACA,eAAA;AAEV;AADU;EACE,gCAAA;EACA,sBAAA;AAGZ","sourcesContent":["// *** inherited from customers Table styles ***\n.EditEmailRow {\n  border-bottom: solid 1px lightgray;\n  width: 100%;\n  display: flex;\n  justify-content: baseline;\n  align-items: baseline;\n  padding: 2.5% 1%;\n  padding-right: 0;\n  .email {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .row {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    .options {\n      & > * {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n      }\n      width: 60%;\n      text-align: right;\n      display: grid;\n      grid-template-columns: 1fr 1fr 1fr 0.5fr;\n\n      & > div {\n        padding: 0 5%;\n        border-right: solid 2px lightgray;\n        .setItem {\n          padding: 5px 10px;\n          border-radius: 15px;\n          cursor: pointer;\n          &:hover {\n            transition: all 0.2s ease-in-out;\n            transform: scale(1.05);\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
