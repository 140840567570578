import './App.scss'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { canHandleRoute, getRoutingComponent } from 'supertokens-auth-react/ui'
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui'
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BASE_PATH, SuperTokensConfig } from './config'
import { store } from './store/store'
import ThemeCustomization from './themes'
import Locales from './ui-component/extended/Locales'
import { ConfigProvider } from './contexts/ConfigContext'
import { AppChilds } from './AppChilds'
import OrderPreviews from './views/orderPreviews'
import OrderPreviewsApproval from './views/orderPreviewsApproval/OrderPreviewsApproval'
import { ResetPassword, SignInSignUp } from './views/auth/signInSignp/SignInSignUp'
import { ToastProvider } from './contexts/ToastContext'

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_API,
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: process.env.REACT_APP_GRAPH_API,
    headers: { 'Apollo-Require-Preflight': 'true' },
  }),
})

SuperTokens.init(SuperTokensConfig)

function App() {
  const orderPreviewsRoute = window.location.href.includes('order-previews')
  const orderPreviewsApprovalRoute = window.location.href.includes('approve-order')
  const isPasswordResetRoute = window.location.href.includes('reset-password')
  if (canHandleRoute([EmailPasswordPreBuiltUI])) {
    return <SignInSignUp />
  }
  if (canHandleRoute([EmailVerificationPreBuiltUI])) {
    return getRoutingComponent([EmailVerificationPreBuiltUI])
  }

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Locales>
            <ConfigProvider>
              <ToastProvider>
                <BrowserRouter basename={BASE_PATH}>
                  <ThemeCustomization>
                    {!orderPreviewsRoute &&
                      !orderPreviewsApprovalRoute &&
                      !isPasswordResetRoute && (
                        <SuperTokensWrapper>
                          <AppChilds />
                        </SuperTokensWrapper>
                      )}

                    {isPasswordResetRoute && <ResetPassword />}
                    {orderPreviewsApprovalRoute && <OrderPreviewsApproval />}
                    {orderPreviewsRoute && <OrderPreviews />}
                  </ThemeCustomization>
                </BrowserRouter>
              </ToastProvider>
            </ConfigProvider>
          </Locales>
        </LocalizationProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default App
