import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useToast } from '../../../../contexts/ToastContext'
import axiosInstance from '../../../../utils/axiosInstance'
import Loading from '../../../../ui-component/loading/Loading'

// Integration Provider options
const INTEGRATION_PROVIDERS = [
  { value: 'hr4you', label: 'HR4YOU' },
  { value: 'concludis-internal', label: 'Concludis Internal' },
  { value: 'onlyfy', label: 'Onlyfy' },
]

interface IntegrationConfig {
  username?: string
  password?: string
  companyIdentifier?: string
  apiKey?: string
}

interface IntegrationData {
  config: IntegrationConfig
  active: boolean
  type: string
}

interface Integration {
  id: string
  externalId: string | null
  type: string
  config: IntegrationConfig
  active: boolean
  jobCount?: number // Add jobCount field
}

interface ImportJobsResponse {
  message: string
  count: number
  jobs: any[]
}

export const Integrations = ({ id }: { id: string }) => {
  const [selectedProvider, setSelectedProvider] = useState('')
  const [config, setConfig] = useState<IntegrationConfig>({})
  const [loading, setLoading] = useState(false)
  const [existingIntegrations, setExistingIntegrations] = useState<Integration[]>([])
  const [loadingIntegrations, setLoadingIntegrations] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [integrationToDelete, setIntegrationToDelete] = useState<string | null>(null)

  const intl = useIntl()
  const { showToast } = useToast()

  // Load existing integrations when component mounts
  useEffect(() => {
    if (id) {
      fetchExistingIntegrations()
    }
  }, [id])

  const fetchExistingIntegrations = async () => {
    setLoadingIntegrations(true)
    try {
      const response = await axiosInstance.get(`/integrations/configurations/customer/${id}`)
      const integrationsWithJobs = await Promise.all(
        response.data.map(async (integration: Integration) => {
          try {
            // Get job count for each integration
            const jobsResponse = await axiosInstance.get(
              `/integrations/configurations/${integration.id}/jobs/count`
            )
            return {
              ...integration,
              jobCount: jobsResponse.data.count,
            }
          } catch (err) {
            console.error(`Error fetching job count for integration ${integration.id}:`, err)
            return {
              ...integration,
              jobCount: 0,
            }
          }
        })
      )
      setExistingIntegrations(integrationsWithJobs)
    } catch (err) {
      console.error('Error fetching integrations:', err)
      showToast(
        intl.formatMessage({
          id: 'integration-load-error',
          defaultMessage: 'Error loading integrations',
        }),
        'error'
      )
    } finally {
      setLoadingIntegrations(false)
    }
  }

  const handleProviderChange = (event: SelectChangeEvent) => {
    const newProvider = event.target.value
    if (existingIntegrations.some((integration) => integration.type === newProvider)) {
      showToast(
        intl.formatMessage({
          id: 'integration-type-exists',
          defaultMessage: 'Integration type already exists',
        }),
        'error'
      )
      return
    }
    setSelectedProvider(newProvider)
    setConfig({}) // Reset config when provider changes
  }

  const handleConfigChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig((prev) => ({
      ...prev,
      [field]: event.target.value,
    }))
  }

  const renderConfigFields = () => {
    switch (selectedProvider) {
      case 'hr4you':
        return (
          <>
            <TextField
              fullWidth
              label="Username"
              value={config.username || ''}
              onChange={handleConfigChange('username')}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={config.password || ''}
              onChange={handleConfigChange('password')}
              margin="normal"
              required
            />
          </>
        )
      case 'concludis-internal':
        return (
          <>
            <TextField
              fullWidth
              label="Company Identifier"
              value={config.companyIdentifier || ''}
              onChange={handleConfigChange('companyIdentifier')}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Username"
              value={config.username || ''}
              onChange={handleConfigChange('username')}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={config.password || ''}
              onChange={handleConfigChange('password')}
              margin="normal"
              required
            />
          </>
        )
      case 'onlyfy':
        return (
          <TextField
            fullWidth
            label="API Key"
            value={config.apiKey || ''}
            onChange={handleConfigChange('apiKey')}
            margin="normal"
            required
          />
        )
      default:
        return null
    }
  }

  const validateConfig = () => {
    switch (selectedProvider) {
      case 'hr4you':
        return !!config.username && !!config.password
      case 'concludis-internal':
        return !!config.companyIdentifier && !!config.username && !!config.password
      case 'onlyfy':
        return !!config.apiKey
      default:
        return false
    }
  }

  const handleSubmit = async () => {
    if (!selectedProvider) return
    if (!validateConfig()) {
      showToast(
        intl.formatMessage({
          id: 'fill-required-fields',
          defaultMessage: 'Please fill all required fields',
        }),
        'error'
      )
      return
    }

    setLoading(true)
    try {
      const integrationData: IntegrationData = {
        config,
        active: true,
        type: selectedProvider,
      }

      await axiosInstance.post('/integrations/configurations', {
        ...integrationData,
        customerId: id,
      })
      showToast(
        intl.formatMessage({
          id: 'integration-saved',
          defaultMessage: 'Integration saved successfully',
        }),
        'success'
      )

      // Refresh the list of integrations
      await fetchExistingIntegrations()
      setSelectedProvider('')
      setConfig({})
    } catch (error) {
      console.error('Error saving integration:', error)
      showToast(
        intl.formatMessage({
          id: 'integration-save-error',
          defaultMessage: 'Error saving integration',
        }),
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteIntegration = async () => {
    if (!integrationToDelete) return

    setLoading(true)
    try {
      await axiosInstance.delete(`/integrations/configurations/${integrationToDelete}`)
      showToast(
        intl.formatMessage({
          id: 'integration-deleted',
          defaultMessage: 'Integration deleted successfully',
        }),
        'success'
      )
      // Refresh the list of integrations
      await fetchExistingIntegrations()
    } catch (error) {
      console.error('Error deleting integration:', error)
      showToast(
        intl.formatMessage({
          id: 'integration-delete-error',
          defaultMessage: 'Error deleting integration',
        }),
        'error'
      )
    } finally {
      setLoading(false)
      setConfirmDialogOpen(false)
      setIntegrationToDelete(null)
    }
  }

  const openConfirmDialog = (integrationId: string) => {
    setIntegrationToDelete(integrationId)
    setConfirmDialogOpen(true)
  }

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false)
    setIntegrationToDelete(null)
  }

  const handleImportJobs = async (integrationId: string) => {
    setLoading(true)
    try {
      const response = await axiosInstance.get<ImportJobsResponse>(
        `/integrations/configurations/${integrationId}/import-jobs`
      )
      showToast(
        intl.formatMessage(
          { id: 'jobs-imported-successfully', defaultMessage: 'Jobs imported successfully' },
          { count: Number(response.data.count) }
        ),
        'success'
      )
      // Refresh the list of integrations to update job counts
      await fetchExistingIntegrations()
    } catch (error) {
      console.error('Error importing jobs:', error)
      showToast(
        intl.formatMessage({ id: 'jobs-import-error', defaultMessage: 'Error importing jobs' }),
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  if (loading || loadingIntegrations) return <Loading />

  return (
    <Container maxWidth="md" className="integrationsContainer subFormContainer">
      <div className="header">
        <FormattedMessage id="integrations" defaultMessage="Integrations" />
      </div>

      <Grid container className="content" spacing={3}>
        {existingIntegrations.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginBottom: 2.5 }}>
              <FormattedMessage id="existing-integrations" defaultMessage="Existing Integrations" />
            </Typography>
            {existingIntegrations.map((integration) => (
              <Box
                key={integration.id}
                sx={{
                  border: '1px solid #e0e0e0',
                  borderRadius: 2,
                  padding: 2.5,
                  marginBottom: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#fafafa',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  },
                }}
              >
                <div>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: '#1976d2', mb: 0.5 }}
                  >
                    {INTEGRATION_PROVIDERS.find((p) => p.value === integration.type)?.label ||
                      integration.type}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
                    {intl.formatMessage(
                      { id: 'integration-id' },
                      { id: integration.externalId || '-' }
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: integration.jobCount ? '#2e7d32' : '#757575' }}
                  >
                    {intl.formatMessage(
                      { id: 'total-jobs', defaultMessage: 'Total Jobs: {count}' },
                      { count: integration.jobCount || 0 }
                    )}
                  </Typography>
                </div>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => handleImportJobs(integration.id)}
                    sx={{
                      textTransform: 'none',
                      minWidth: '120px',
                      fontWeight: 500,
                    }}
                  >
                    <FormattedMessage id="import-jobs" defaultMessage="Import Jobs" />
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    size="small"
                    onClick={() => openConfirmDialog(integration.id)}
                    sx={{
                      minWidth: 'auto',
                      opacity: 0.7,
                    }}
                  >
                    <FormattedMessage id="delete" defaultMessage="Delete" />
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            <FormattedMessage id="add-integration" defaultMessage="Add Integration" />
          </Typography>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="integration-provider-label">
              <FormattedMessage
                id="select-integration-provider"
                defaultMessage="Select Integration Provider"
              />
            </InputLabel>
            <Select
              labelId="integration-provider-label"
              id="integration-provider"
              value={selectedProvider}
              label={intl.formatMessage({
                id: 'select-integration-provider',
                defaultMessage: 'Select Integration Provider',
              })}
              onChange={handleProviderChange}
            >
              {INTEGRATION_PROVIDERS.map((provider) => {
                const isProviderUsed = existingIntegrations.some(
                  (integration) => integration.type === provider.value
                )
                return (
                  <MenuItem key={provider.value} value={provider.value} disabled={isProviderUsed}>
                    {provider.label}
                    {isProviderUsed && ` (${intl.formatMessage({ id: 'already-configured' })})`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          {selectedProvider && <Box sx={{ marginBottom: 2 }}>{renderConfigFields()}</Box>}

          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!selectedProvider || !validateConfig()}
            >
              <FormattedMessage id="save-integration" defaultMessage="Save Integration" />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {intl.formatMessage({
            id: 'delete-integration-confirm-title',
            defaultMessage: 'Confirm Delete Integration',
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {intl.formatMessage({
              id: 'delete-integration-confirm',
              defaultMessage: 'Are you sure you want to delete this integration?',
            })}
            <br />
            <br />
            {intl.formatMessage({
              id: 'delete-integration-jobs-preserved',
              defaultMessage:
                'Note: The integration will be deleted from both our system and the external ATS service, but any related jobs that have been imported will be preserved.',
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </Button>
          <Button onClick={handleDeleteIntegration} color="error" autoFocus>
            {intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Integrations
