import { useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  Container,
  Drawer,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import useSWR, { SWRResponse } from 'swr'
import { setOrdersFilterQueryParams } from '../../../../../store/slices/filters/filters'
import { useDispatch } from '../../../../../store/store'
import axiosInstance from '../../../../../utils/axiosInstance'

interface Iprops {
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}

export function OrderFilterDrawer({ drawerOpen = false, setDrawerOpen }: Iprops) {
  return (
    <Drawer
      className="filterDrawer"
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Container disableGutters className="drawerContainer" style={{ padding: 16 }}>
        <Container className="header" style={{ padding: 0 }}>
          <Typography variant="h3">Filter</Typography>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            aria-label="close"
          >
            <IconX />
          </IconButton>
        </Container>
        <Container style={{ padding: 0 }}>
          <Filters />
        </Container>
      </Container>
    </Drawer>
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const { data: ordersData }: SWRResponse = useSWR('/orders-filter-values', axiosInstance)
  const orders = ordersData?.data

  interface LabelValue {
    label: string
    value: string
  }

  const [date, setDate] = useState<Dayjs | null>(null)
  const [assignee, setAssignee] = useState<LabelValue | null>(null)
  const [reseller, setReseller] = useState<LabelValue | null>(null)

  useEffect(() => {
    const raw = date?.toDate()
    const createdAt = raw?.toDateString()

    const params = {
      createdAt,
      assignee: assignee?.value,
      reseller: reseller?.value,
    }
    dispatch(setOrdersFilterQueryParams(params))
  }, [date, assignee, reseller, dispatch])

  function clearFilters() {
    setDate(null)
    setAssignee(null)
    setReseller(null)
  }

  return (
    <form>
      <Typography>
        <FormattedMessage id="date-created" />
      </Typography>
      <DatePicker
        disableFuture
        sx={{ width: '100%' }}
        format="DD/MM/YYYY"
        value={date}
        onChange={(newValue) => setDate(newValue)}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
      />
      {orders?.assignees?.length && (
        <>
          <FormControl fullWidth>
            <Typography>
              <FormattedMessage id="assignee" />
            </Typography>
            <Autocomplete
              id="status"
              options={orders?.assignees}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              value={assignee}
              onChange={(event, newValue) => setAssignee(newValue || null)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
            />
          </FormControl>
        </>
      )}
      {orders?.resellers?.length && (
        <>
          <FormControl fullWidth>
            <Typography>
              <FormattedMessage id="reseller" />
            </Typography>
            <Autocomplete
              id="status"
              options={orders?.resellers}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              value={reseller}
              onChange={(event, newValue) => setReseller(newValue || null)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
            />
          </FormControl>
        </>
      )}
      <div className="clear">
        <Button onClick={() => clearFilters()} className="clearButt" fullWidth variant="outlined">
          Clear all
        </Button>
      </div>
    </form>
  )
}
