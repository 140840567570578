import { Box, Button, Container, Dialog, Grid, Tooltip, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR, { SWRResponse } from 'swr'
import { useState } from 'react'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import axiosInstance from '../../../../../../../utils/axiosInstance'
import Loading from '../../../../../../../ui-component/loading/Loading'

interface Props {
  id: string
  open: boolean
  setOpen: (value: boolean) => void
}

export const ExternalAppUserDialog: React.FC<Props> = ({ id, open, setOpen }) => {
  const intl = useIntl()
  const [copied, setCopied] = useState(false)
  const { data: connectedCustomer, isLoading }: SWRResponse = useSWR(
    `/app-users/${id}`,
    axiosInstance
  )
  const data = connectedCustomer?.data?.appUser

  let isConnectedCustomer = true
  if (data?.role === 'externalReseller') {
    !data.customer?.name && (isConnectedCustomer = false)
  }

  isLoading && <Loading />

  // Get the API key from the latest access token
  const latestApiKey = data?.accessToken?.[0]?.apiKey
  const apiUrl = latestApiKey
    ? `https://api.recruspin.com/campaigns-api?api_key=${latestApiKey}`
    : null

  const handleCopyClick = () => {
    if (apiUrl) {
      navigator.clipboard.writeText(apiUrl).then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      })
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="ReadEditOrder"
        fullWidth
        maxWidth="md"
      >
        <Container maxWidth="md" className="formContainer">
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="user-details" />
            </div>

            {apiUrl && (
              <Grid spacing={2} container className="content">
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="api-link" />
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      sx={{
                        maxWidth: '90%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mr: 1,
                      }}
                    >
                      {apiUrl}
                    </Typography>
                    <Tooltip
                      title={
                        copied
                          ? intl.formatMessage({ id: 'copied' })
                          : intl.formatMessage({ id: 'copy' })
                      }
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        color={copied ? 'success' : 'primary'}
                        onClick={handleCopyClick}
                        sx={{ minWidth: '40px', ml: 1 }}
                      >
                        {copied ? <IconCheck size={18} /> : <IconCopy size={18} />}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid spacing={2} container className="content">
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="app-user-name" />
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{`${data?.firstName} ${data?.lastName}`}</Typography>
              </Grid>
            </Grid>

            <Grid spacing={2} container className="content">
              {!isConnectedCustomer && (
                <Grid item xs={3}>
                  <Typography color="red" variant="h5">
                    <FormattedMessage id="no-connected-customer" />
                  </Typography>
                </Grid>
              )}
              {data?.role === 'externalReseller' && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="connected-customer" />
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">{data?.customer?.name}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Container>
      </Dialog>
    </div>
  )
}
