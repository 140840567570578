// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileViewEditRow,
.FileViewRow {
  display: flex;
  align-items: baseline;
  padding: 4px 10px;
}
.FileViewEditRow:not(:last-of-type),
.FileViewRow:not(:last-of-type) {
  border-bottom: 1px solid rgba(204, 204, 204, 0.7137254902);
}
.FileViewEditRow .iconAndName,
.FileViewRow .iconAndName {
  margin-left: 16px;
  overflow: hidden;
  display: flex;
}
.FileViewEditRow .iconAndName:hover .fileName,
.FileViewRow .iconAndName:hover .fileName {
  cursor: pointer;
  color: #007bff;
  transform: scale(1.001);
}
.FileViewEditRow .fileName,
.FileViewRow .fileName {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-bottom: 0;
}
.FileViewEditRow .icons,
.FileViewRow .icons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
}

.mediaFiles img, .mediaFiles video {
  max-width: 100%;
  max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/ui-component/fileViewRow/style.scss"],"names":[],"mappings":"AAAA;;EAEE,aAAA;EACA,qBAAA;EACA,iBAAA;AACF;AAAE;;EACE,0DAAA;AAGJ;AADE;;EACE,iBAAA;EACA,gBAAA;EACA,aAAA;AAIJ;AAFM;;EACE,eAAA;EACA,cAAA;EACA,uBAAA;AAKR;AADE;;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AAIJ;AAFE;;EACE,aAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;AAKJ;;AAAE;EACE,eAAA;EACA,gBAAA;AAGJ","sourcesContent":[".FileViewEditRow,\n.FileViewRow {\n  display: flex;\n  align-items: baseline;\n  padding: 4px 10px;\n  &:not(:last-of-type) {\n    border-bottom: 1px solid #ccccccb6;\n  }\n  .iconAndName {\n    margin-left: 16px;\n    overflow: hidden;\n    display: flex;\n    &:hover {\n      .fileName {\n        cursor: pointer;\n        color: #007bff;\n        transform: scale(1.001);\n      }\n    }\n  }\n  .fileName {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 16px;\n    margin-bottom: 0;\n  }\n  .icons {\n    display: flex;\n    justify-content: flex-end;\n    margin-left: auto;\n    margin-right: 0;\n  }\n}\n\n.mediaFiles {\n  img, video {\n    max-width: 100%;\n    max-height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
