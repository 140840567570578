import { Container, Dialog, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import useSWR, { SWRResponse } from 'swr'
import axiosInstance from '../../../../../../../utils/axiosInstance'
import Loading from '../../../../../../../ui-component/loading/Loading'

interface Props {
  id: string
  open: boolean
  setOpen: (value: boolean) => void
}

export const InternalAppUserDialog: React.FC<Props> = ({ id, open, setOpen }) => {
  const { data: connectedCustomer, isLoading }: SWRResponse = useSWR(
    `/app-users/${id}`,
    axiosInstance
  )
  const data = connectedCustomer?.data?.appUser

  isLoading && <Loading />

  const todoOrders = data?.tasks.filter((order) => order.status === 'todo')
  const inProgressOrders = data?.tasks.filter((order) => order.status === 'inProgress')
  const activeOrders = data?.tasks.filter((order) => order.status === 'active')
  const waitingForApprovalOrders = data?.tasks.filter(
    (order) => order.status === 'waitingForApproval'
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="ReadEditOrder"
        fullWidth
        maxWidth="md"
      >
        <Container maxWidth="md" className="formContainer">
          <div className="customerDetails subFormContainer">
            <div className="header">
              <FormattedMessage id="user-details" />
            </div>
            <Grid spacing={2} container className="content">
              <>
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="asana-id" />
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{data?.asanaId}</Typography>
                </Grid>
              </>

              {todoOrders?.length > 0 && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="todo-orders" />
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <ul>
                      {todoOrders.map((order) => (
                        <li key={order.id}>{order?.jobName}</li>
                      ))}
                    </ul>
                  </Grid>
                </>
              )}

              {inProgressOrders?.length > 0 && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="in-progress-orders" />
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <ul>
                      {inProgressOrders.map((order) => (
                        <li key={order.id}>{order.jobName}</li>
                      ))}
                    </ul>
                  </Grid>
                </>
              )}

              {activeOrders?.length > 0 && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="active-orders" />
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <ul>
                      {activeOrders.map((order) => (
                        <li key={order.id}>{order.jobName}</li>
                      ))}
                    </ul>
                  </Grid>
                </>
              )}

              {waitingForApprovalOrders?.length > 0 && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5">
                      <FormattedMessage id="waiting-for-approval-orders" />
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <ul>
                      {waitingForApprovalOrders.map((order) => (
                        <li key={order.id}>{order.jobName}</li>
                      ))}
                    </ul>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Container>
      </Dialog>
    </div>
  )
}
