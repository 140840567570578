// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUploadArea .dropzone {
  text-align: center;
  cursor: pointer;
}
.FileUploadArea .dropzone .button,
.FileUploadArea .dropzone .rectangel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  margin: auto auto;
}
.FileUploadArea .dropzone p {
  margin: 0;
}
.FileUploadArea .dropzone .supportedFormats {
  margin-top: 8px;
}
.FileUploadArea .dropzone .rectangel {
  border: rgba(44, 29, 255, 0.7) 1px dashed;
  border-radius: 15px;
  width: 100%;
  padding: 32px 0;
  font-size: 12px;
}
.FileUploadArea .dropzone .rectangel:hover {
  background-color: rgb(243, 243, 243);
}
.FileUploadArea .dropzone .button {
  margin-top: 8px;
  height: 32px;
  min-width: 150px;
}
.FileUploadArea .ResultFile {
  align-items: center;
}
.FileUploadArea .ResultFileList {
  margin-top: 24px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/ui-component/fileUpload/style.scss"],"names":[],"mappings":"AAME;EACE,kBAAA;EACA,eAAA;AALJ;AAMI;;EAEE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AAJN;AAMI;EACE,SAAA;AAJN;AAMI;EACE,eAAA;AAJN;AAMI;EACE,yCAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AAJN;AAMM;EACE,oCAAA;AAJR;AAOI;EACE,eAAA;EACA,YAAA;EACA,gBAAA;AALN;AAQE;EACE,mBAAA;AANJ;AASE;EACE,gBAAA;EACA,gBAAA;AAPJ","sourcesContent":[".FileUploadArea {\n  $borderColor: rgba(\n    $color: #94939349,\n    $alpha: 0.7,\n  );\n\n  .dropzone {\n    text-align: center;\n    cursor: pointer;\n    .button,\n    .rectangel {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      font-size: larger;\n      font-weight: 400;\n      border-radius: 10px;\n      text-align: center;\n      margin: auto auto;\n    }    \n    p {\n      margin: 0;\n    }\n    .supportedFormats {\n      margin-top: 8px;\n    }\n    .rectangel {\n      border: rgba($color: #2c1dff94, $alpha: 0.7) 1px dashed;\n      border-radius: 15px;\n      width: 100%;\n      padding: 32px 0;\n      font-size: 12px;\n\n      &:hover {\n        background-color: rgb(243, 243, 243);\n      }\n    }\n    .button {\n      margin-top: 8px;\n      height: 32px;\n      min-width: 150px;\n    }\n  }\n  .ResultFile {\n    align-items: center;\n  }\n\n  .ResultFileList {\n    margin-top: 24px;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
